<template>
    <section
        class="hero-section"
        style="background: url('https://kupi.41custom.com/wp-content/uploads/2022/04/banner-usuarios.png') no-repeat center center / cover;">
        <div class="video-section-wrap">
            <div class="background-video-overly ptb-100">
                <div
                    id="heroPlayer"
                    class="player"
                    data-property="{videoURL:'https://www.youtube.com/watch?v=gOqlwlQjVis',containment:'.video-section-wrap', quality:'highres', autoPlay:true, showControls: false, startAt:0, mute:true, opacity: 1}"
                ></div>
                <div class="container">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-md-8 col-lg-7">
                            <div class="hero-content-left text-white text-center mt-5 ptb-100">
                                <h1 class="text-white">Usuarios</h1>
                                <p class="lead">
                                    Conoce todo lo que puedes hacer con Kupi <br>Hacemos más fácil y seguro el proceso de tus pagos
                                </p>
                                <a @click="showYouTubeModalQr" href="#" class="btn google-play-btn mr-5">Paga con QR</a>
                                <a @click="showYouTubeModalPin" href="#" class="btn google-play-btn">Paga con pin</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <YTModal
            :url="url"
            v-if="showModal"
            @close="showModal = false"
        >
            This is my first modal
        </YTModal>
    </section>
    
</template>

<script>
import $ from "jquery";
import YTModal from "../../components/YTModal";

export default {
    name: "Banner",
    components: { YTModal },
    data: function () {
        return {
            showModal: false,
            url: ''
        };
    },
    methods: {
        showYouTubeModalQr: function (e) {
            this.url = 'https://youtu.be/gAFlUsLCaYQ';
            e.preventDefault();
            this.showModal = true;
        },
        showYouTubeModalPin: function (e) {
            this.url = 'https://youtu.be/ErfxclpoVAA';
            e.preventDefault();
            this.showModal = true;
        },
    },
    mounted() {
        // $("#heroPlayer").YTPlayer();
        this.player = $("#heroPlayer").YTPlayer();
        setTimeout(() => {
            this.player.YTPlayer();
        }, 2000);
    },
};
</script>

<style scoped>
    h1 {
        font-weight: 800 !important;
    }
</style>