<template>
    <div>
        <nav-bar />
        <div class="main">
            <ConveniosKupi/>
        </div>
    </div>
</template>

<script>
import NavBar from "./NavBar";
import ConveniosKupi from "./ConveniosKupi";

export default {
    name: 'ConveniosCoprocenva',
    components: {
        NavBar,
        ConveniosKupi,
    },
}
</script>

<style>

</style>