<template>
  <!-- <section class="hero-section hero-section-2 ptb-100"> -->
  <!-- <section class="hero-section">
    <div class="circles">
      <div class="point animated-point-1"></div>
      <div class="point animated-point-2"></div>
      <div class="point animated-point-3"></div>
      <div class="point animated-point-4"></div>
      <div class="point animated-point-5"></div> 
      <div class="point animated-point-6"></div>
      <div class="point animated-point-7"></div>
      <div class="point animated-point-8"></div>
      <div class="point animated-point-9"></div>
    </div>
    <div class="container" style="background-color: #002856; max-width: 100%;">
      <div>
        <div class="banner-first">
          <div class="hero-content">
            <h1 class="text-white">
              <span>CALIDAD DE EMPAQUE Y</span><br>
              <span>SOLUCIONES DE PRODUCTIVIDAD</span><br>
              <span class="secondary-text">Piezas integradas y Servicios Técnicos para Envolvedoras Horizontales y Envasadoras Verticales FFS</span>
            </h1>
            <button>Empieza ahora</button>
            <p class="lead">
              Porque las mejores marcas reciben Kupi.
            </p>
            <a @click="showYouTubeModal" href="#" class="btn app-store-btn col-5 mr-3">¿Qué es Kupi?</a>
            <router-link class="btn app-store-btn col-5" to="/convenios">¿Dónde comprar?</router-link>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="hero-animation-img">
            //Imagen del banner, chica, acá debe ir videos
            <img
              class="d-block"
              src="img/pagina_inicio/banner.jpg"
              alt="animation image"
              width="100%"
            />
            <img
              class="img-fluid d-none d-lg-block animation-two"
              src="img/hero-animation-01.svg"
              alt="animation image"
              width="120"/>
            <img
              class="img-fluid d-none d-lg-block animation-three"
              src="img/hero-animation-02.svg"
              alt="animation image"
              width="120"/>
            <img
              class="img-fluid d-none d-lg-block animation-four"
              src="img/hero-animation-03.svg"
              alt="animation image"
              width="230"/>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="background-color: #A32035; max-width: 100%; height: 150px;">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="hero-content-center">
            <h1 class="text-white" style="text-align: center; padding-top: 50px;">
              <span>Nuestras Soluciones Integradas</span>
            </h1>
            <p class="lead">
              Porque las mejores marcas reciben Kupi.
            </p>
            <a @click="showYouTubeModal" href="#" class="btn app-store-btn col-5 mr-3">¿Qué es Kupi?</a>
            <router-link class="btn app-store-btn col-5" to="/convenios">¿Dónde comprar?</router-link>
          </div>
        </div>
      </div>
    </div>
    <YTModal
        url="https://www.youtube.com/watch?v=VY7U1oIPiMM&feature=emb_imp_woyt"
        v-if="showModal"
        @close="showModal = false"
    >
        This is my first modal
    </YTModal>
  </section> -->
  <section>
        <div>
            <div>
                <!-- <div class="col-md-6 col-lg-7">
                    <div class="position-absolute text-white">
                        <h1 class="text-white mb-10">
                            Soluciones de Calidad y Productividad de Paquetes
                        </h1>
                        <h4 class="text-white mb-10">Tecnología para mejorar la vida.</h4>
                        <p class="lead" style="line-height: 24px;">
                          Piezas integradas y servicios técnicos para envolvedoras de flujo y ensacadoras verticales FFS.
                        </p>
                        <p class="lead" style="line-height: 24px;">
                            La Revolución Kupi está liberando el potencial de las estrategias de bienestar empresarial, fidelización de clientes y retención de talento en múltiples industrias: Cooperativas, Fondos de empleados, Cajas de compensación, Empresas de distintos sectores y entidades Gubernamentales al elminar los procesos obsoletos y burocráticos para la asignación de Bonos, Subsidios y Cupos de crédito 100% digitales.
                        </p>
                        <a href="/contact" class="btn google-play-btn boton">CONTÁCTANOS</a>
                    </div>
                </div> -->
                <div class="">
                  <VideoFondo />
                </div>
                <!-- <div class="col-md-6 col-lg-5">
                    <div class="offer-tag-wrap position-relative z-index">
                        <img
                            src="img/sobre_kupi/banner1.jpg"
                            alt="app"
                            class="img-fluid"
                            style="border-radius: 30px;"
                        />
                        <videos url="https://www.youtube.com/watch?v=Qt5cRQhV70A&pp=ygULcXVlIGVzIGt1cGk%3D"/>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
</template>

<script>
import VideoFondo from '../../components/VideoFondo.vue';

export default {
  components: {
    VideoFondo,
  },
};
// import YTModal from "../../components/YTModal";

// export default {
//   name: 'Banner',
//   components: { YTModal },
//     data: function () {
//         return {
//             showModal: false,
//         };
//     },
//     methods: {
//         showYouTubeModal: function (e) {
//             e.preventDefault();
//             this.showModal = true;
//         },
//     },
// };
</script>

<style>
</style>