<template>
    <div>
        <nav-bar />
        <div class="main">
            <Banner/>
            <Clients title="Marcas Aliadas" :images="images"/>
            <SiteFooter/>
        </div>
    </div>
</template>

<script>
import Clients from '../commons/Clients';
import NavBar from "../commons/NavBar";
import SiteFooter from "../commons/SiteFooter";
import Banner from './Banner';

export default {
    name: 'AsignacionBonos',
    data: function() {
        return {
            images: [
                'https://s3.amazonaws.com/app.kupi.s3/images/web/home/comercios/burger-logo-300x171.png',
                'https://s3.amazonaws.com/app.kupi.s3/images/web/home/comercios/calzatodo-logo-300x171.png',
                'https://s3.amazonaws.com/app.kupi.s3/images/web/home/comercios/chacalacas-logo-300x171.png',
                'https://s3.amazonaws.com/app.kupi.s3/images/web/home/comercios/donjediondo-logo-300x171.png',
                'https://s3.amazonaws.com/app.kupi.s3/images/web/home/comercios/DR_BANNER-350X200-300x171.png',
                'https://s3.amazonaws.com/app.kupi.s3/images/web/home/comercios/el-templo-de-la-moda-logo-300x171.png',
                'https://s3.amazonaws.com/app.kupi.s3/images/web/home/comercios/frisby-logo-300x171.png'
            ]
        }
    },
    components: {
        NavBar,
        Banner,
        Clients,
        SiteFooter
    },
}
</script>

<style>

</style>