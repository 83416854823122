<template>
    <header class="header">
        <!--start navbar-->
        <nav class="navbar navbar-expand-lg fixed-top"
            v-bind:class="{
                affix: hasAffix,
                'custom-nav': coloredLogo,
                'bg-transparent': !coloredLogo,
                'white-bg': coloredLogo,
            }">
            <div class="container mt-2" id="">
                <a class="navbar-brand" href="/">
                    <img :src="coloredLogo ? 'img/logo/logo-color-1x.png' : 'img/logo/logo-white-1x.png'"
                        width="120" alt="logo" class="img-fluid"/>
                </a>
            </div>
        </nav>
        <!--end navbar-->
    </header>
</template>

<script>
export default {
    props: {
        coloredLogo: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            windowTop: 0,
            collapsed: true,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
    },
    methods: {
        onScroll: function () {
            this.windowTop = window.top.scrollY;
        },
        mobileNavClicked: function () {
            this.collapsed = !this.collapsed;
        },
    },
    computed: {
        hasAffix: function () {
            return this.windowTop > 0;
        },
    },
};
</script>

<style>
  #container-header{
    align-items: flex-start;
  }
</style>
