<template>
    <div>
        <router-link :to="route">
            <div class="shadow single-promo-hover single-promo-1 rounded text-center white-bg"
            :style="'background: url(' + img + ') no-repeat center center / cover'">
                <div class="title">
                    <h5>{{ title }}</h5>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>

// import img1 from 'assets/img/pagina_inicio/CONFIANZA.png';
export default {
    name: "CardERecurso",
    
    props: {
        title: {type: String},
        route: {type: String, default: '#'},
        img: {type: String}
    }
};
</script>

<style scoped>
    .shadow {
        margin: 20px 0;
        box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.5);
        border-radius: 1.5rem !important;
        width: 300px;
        height: 250px !important;
        /* --------------------- */
        display: flex;
        justify-items: start;
        align-items: flex-start;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: flex-end
    }

    .shadow h5{
        color: #282447;
        margin: 15px 0;
    }

    .title {
        width: inherit;
        background-color: #E4E4E4;
        border-radius: 1.5rem !important;
    }
</style>