<template>
  <section id="about" class="about-us pb-80 gray-light-bg">
    <div class="container">
      <div v-if="imgLeft" class="row align-items-center justify-content-between">
        <imgBeneficio :src="beneficio.img" :radiusLeft="imgLeft"/>
        <listBeneficios :items="beneficio.items" :title="beneficio.title" :href="beneficio.href"/>
      </div>
      <div v-else class="row align-items-center justify-content-between">
        <listBeneficios :items="beneficio.items" :title="beneficio.title" :href="beneficio.href"/>
        <imgBeneficio :src="beneficio.img" :radiusLeft="imgLeft"/>
      </div>
    </div>
  </section>
</template>

<script>
import imgBeneficio from '../beneficios/imgBeneficio';
import listBeneficios from '../beneficios/listBeneficios';

export default {
  name: "BeneficioItem",
  components: {
    imgBeneficio,
    listBeneficios
  },
  props: {
    imgLeft: {
      type: Boolean,
      default: true
    },
    beneficio: {
      default: {
        title: '',
        img: '',
        href: '#',
        items: []
      }
    }
  }
};
</script>

<style scoped>
</style>