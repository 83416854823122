<template>
  <div>
    <nav-bar />
    <div class="main">
      <Banner
          title="EMBALAJE"
          text1="Soporte técnico de máquinas y equipos de embalaje."       
          img1="img/recursos/embalaje1.jpg"        
          img2="img/recursos/embalaje2.jpg"        
          img3="img/recursos/embalaje3.jpg"        
          img4="img/recursos/embalaje4.jpg"        
          img5="img/recursos/embalaje5.jpg"                       
      />
      <sectionRobotica
        title="Nuestro servicio:"
        subtitle1="•	Evaluación, Instalación, configuración y puesta en marcha de máquinas y equipos."
        subtitle2="•	Creación de estándares sostenibles en el tiempo."
        subtitle3="•	Entrenamiento en el uso de máquinas."
        subtitle4="•	Resolución de problemas técnicos."
        subtitle5="•	Planes de mantenimiento."
        subtitle6="•	Suministro de repuestos."
        title2="Aplicaciones en la industria:"
        subtitle21="•	Manufacturera."
        subtitle23="•	Alimenticia."
        subtitle24="•	Farmacéutica."
        subtitle25="•	Logística."
        subtitle26="•	Química."
      />
    </div>
    <SiteFooter/>
  </div>
</template>

<script>

import NavBar from "../../commons/NavBar.vue";
import Banner from "../../Industrias/components/Banner.vue"
import SectionRobotica from "../../Industrias/components/SectionRobotica.vue";
import SiteFooter from '../../../views/commons/SiteFooter';



export default {
    name: 'Robotica',
    components: {
    NavBar,
    Banner,
    SectionRobotica,
    SiteFooter
},
}
</script>