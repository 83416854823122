<template>
    <section class="hero-section hero-section-3 ptb-100">
        <!--animated circle shape start-->
        <div class="circles">
            <div class="point animated-point-1"></div>
            <div class="point animated-point-2"></div>
            <div class="point animated-point-3"></div>
            <div class="point animated-point-4"></div>
            <div class="point animated-point-5"></div>
            <div class="point animated-point-6"></div>
        </div>
        <!--animated circle shape end-->
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-6 col-lg-6">
                    <div class="hero-content-left ptb-100">
                        <h1>
                            <span>Best way to connect with your customers</span>
                        </h1>
                        <p class="lead">
                            Conveniently generate multifunctional markets and
                            B2C vortals. Uniquely enable inexpensive materials
                            rather than sticky products.
                        </p>

                        <a href="#" class="btn solid-btn">Contact with us</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-5">
                    <div class="hero-animation-img">
                        <img
                            class="img-fluid d-block m-auto animation-one"
                            src="img/Telemedicine_01.svg"
                            width="150"
                            alt="animation image"
                        />
                        <img
                            class="img-fluid d-none d-lg-block animation-two"
                            src="img/hero-animation-01.svg"
                            alt="animation image"
                            width="120"
                        />
                        <img
                            class="img-fluid d-none d-lg-block animation-three"
                            src="img/Telemedicine_03.svg"
                            alt="animation image"
                            width="120"
                        />
                        <img
                            class="img-fluid d-none d-lg-block animation-four"
                            src="img/hero-animation-03.svg"
                            alt="animation image"
                            width="200"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!--shape image start-->
        <img
            src="img/hero-bg-shape-2.svg"
            class="shape-image"
            alt="shape image"
        />
        <!--shape image end-->
    </section>
</template>

<script>
export default {
    name: "Banner",
};
</script>

<style>
</style>