<template>
    <section
        id="pricing"
        class="package-section ptb-100"
        v-bind:class="isGray ? 'gray-light-bg' : ''"
    >
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>
                            Afforadble Pricing and Packages <br />
                            choose your best one
                        </h2>
                        <p class="lead">
                            Monotonectally grow strategic process improvements
                            vis-a-vis integrated resources.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md">
                    <div class="card text-center single-pricing-pack">
                        <div class="pt-4"><h5>Basic</h5></div>
                        <div class="pricing-img mt-4">
                            <img
                                src="img/basic.svg"
                                alt="pricing img"
                                class="img-fluid"
                            />
                        </div>
                        <div class="card-header py-4 border-0 pricing-header">
                            <div class="h1 text-center mb-0">
                                $<span class="price font-weight-bolder"
                                    >29</span
                                >
                            </div>
                        </div>
                        <div class="card-body">
                            <ul
                                class="list-unstyled text-sm mb-4 pricing-feature-list"
                            >
                                <li>Push Notifications</li>
                                <li>Data Transfer</li>
                                <li>SQL Database</li>
                                <li>Search & SEO Analytics</li>
                                <li>24/7 Phone Support</li>
                                <li>2 months technical support</li>
                                <li>2+ profitable keyword</li>
                            </ul>
                            <a
                                href="#"
                                class="btn outline-btn mb-3"
                                target="_blank"
                                >Purchase now</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md">
                    <div
                        class="card popular-price text-center single-pricing-pack"
                    >
                        <div class="pt-4"><h5>Standard</h5></div>
                        <div class="pricing-img mt-4">
                            <img
                                src="img/standard.svg"
                                alt="pricing img"
                                class="img-fluid"
                            />
                        </div>
                        <div class="card-header py-4 border-0 pricing-header">
                            <div class="h1 text-center mb-0">
                                $<span class="price font-weight-bolder"
                                    >149</span
                                >
                            </div>
                        </div>
                        <div class="card-body">
                            <ul
                                class="list-unstyled text-sm mb-4 pricing-feature-list"
                            >
                                <li>Push Notifications</li>
                                <li>Data Transfer</li>
                                <li>SQL Database</li>
                                <li>Search & SEO Analytics</li>
                                <li>24/7 Phone Support</li>
                                <li>1 Year technical support</li>
                                <li>50+ profitable keyword</li>
                            </ul>
                            <a
                                href="#"
                                class="btn solid-btn mb-3"
                                target="_blank"
                                >Purchase now</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md">
                    <div class="card text-center single-pricing-pack">
                        <div class="pt-4"><h5>Unlimited</h5></div>
                        <div class="pricing-img mt-4">
                            <img
                                src="img/unlimited.svg"
                                alt="pricing img"
                                class="img-fluid"
                            />
                        </div>
                        <div class="card-header py-4 border-0 pricing-header">
                            <div class="h1 text-center mb-0">
                                $<span class="price font-weight-bolder"
                                    >39</span
                                >
                            </div>
                        </div>
                        <div class="card-body">
                            <ul
                                class="list-unstyled text-sm mb-4 pricing-feature-list"
                            >
                                <li>Push Notifications</li>
                                <li>Data Transfer</li>
                                <li>SQL Database</li>
                                <li>Search & SEO Analytics</li>
                                <li>24/7 Phone Support</li>
                                <li>6 months technical support</li>
                                <li>10+ profitable keyword</li>
                            </ul>
                            <a
                                href="#"
                                class="btn outline-btn mb-3"
                                target="_blank"
                                >Purchase now</a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 text-center">
                <p class="mb-2">
                    If you need custom services or Need more?
                    <a href="#" class="color-secondary"> Contact us </a>
                </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Pricing",
    props: {
        isGray: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style>
</style>