<template>
    <div>
        <router-link :to="route">
            <div class="shadow single-promo-hover single-promo-1 rounded text-center white-bg pl-4 pr-4"
            :style="'background: url(' + img + ') no-repeat center center / cover'">
                <h4>{{ title }}</h4>
                <p>{{ descripcion }}</p>
            </div>
        </router-link>
    </div>
</template>

<script>

// import img1 from 'assets/img/pagina_inicio/CONFIANZA.png';
export default {
    name: "CardEstrategias",
    
    props: {
        title: {type: String},
        descripcion: {type: String},
        route: {type: String, default: '#'},
        img: {type: String},
    }
};
</script>

<style scoped>
    .shadow {
        margin: 20px 0;
        box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.5);
        border-radius: 1.5rem !important;
        width: 100%;
        height: 300px !important;
        /* --------------------- */
        display: flex;
        justify-items: start;
        align-items: flex-start;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: flex-end
    }

    .shadow h4{
        color: #282447;
    }

    .shadow p{
        color: #282447;
        text-align: left;
        line-height: 20px;
    }
</style>