<template>
    <section class="team-member-section ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-8">
                    <div class="section-heading text-center mb-4">
                        <h2>Meet Our team members</h2>
                        <p class="lead">
                            Following reasons show advantages of adding AppCo to
                            your lead pages, demos and checkouts
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div
                        class="single-team-member position-relative my-lg-3 my-md-3 my-sm-0"
                    >
                        <div class="team-image">
                            <img
                                src="img/team-4.jpg"
                                alt="Team Members"
                                class="img-fluid rounded"
                            />
                        </div>
                        <div
                            class="team-info text-white rounded d-flex flex-column align-items-center justify-content-center"
                        >
                            <h5 class="mb-0">Edna Mason</h5>
                            <h6>Senior Designer</h6>
                            <ul
                                class="list-inline team-social social-icon my-4 text-white"
                            >
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-facebook"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-twitter"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-github"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-dribbble"></span
                                    ></a>
                                </li>
                            </ul>
                            <a
                                href="team-single.html"
                                target="_blank"
                                class="btn app-store-btn"
                                >View details</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div
                        class="single-team-member position-relative my-lg-3 my-md-3 my-sm-0"
                    >
                        <div class="team-image">
                            <img
                                src="img/team-1.jpg"
                                alt="Team Members"
                                class="img-fluid rounded"
                            />
                        </div>
                        <div
                            class="team-info text-white rounded d-flex flex-column align-items-center justify-content-center"
                        >
                            <h5 class="mb-0">Edna Mason</h5>
                            <h6>Senior Designer</h6>
                            <ul
                                class="list-inline team-social social-icon my-4 text-white"
                            >
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-facebook"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-twitter"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-github"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-dribbble"></span
                                    ></a>
                                </li>
                            </ul>
                            <a
                                href="team-single.html"
                                target="_blank"
                                class="btn app-store-btn"
                                >View details</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div
                        class="single-team-member position-relative my-lg-3 my-md-3 my-sm-0"
                    >
                        <div class="team-image">
                            <img
                                src="img/team-2.jpg"
                                alt="Team Members"
                                class="img-fluid rounded"
                            />
                        </div>
                        <div
                            class="team-info text-white rounded d-flex flex-column align-items-center justify-content-center"
                        >
                            <h5 class="mb-0">Edna Mason</h5>
                            <h6>Senior Designer</h6>
                            <ul
                                class="list-inline team-social social-icon my-4 text-white"
                            >
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-facebook"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-twitter"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-github"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-dribbble"></span
                                    ></a>
                                </li>
                            </ul>
                            <a
                                href="team-single.html"
                                target="_blank"
                                class="btn app-store-btn"
                                >View details</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div
                        class="single-team-member position-relative my-lg-3 my-md-3 my-sm-0"
                    >
                        <div class="team-image">
                            <img
                                src="img/team-3.jpg"
                                alt="Team Members"
                                class="img-fluid rounded"
                            />
                        </div>
                        <div
                            class="team-info text-white rounded d-flex flex-column align-items-center justify-content-center"
                        >
                            <h5 class="mb-0">Edna Mason</h5>
                            <h6>Senior Designer</h6>
                            <ul
                                class="list-inline team-social social-icon my-4 text-white"
                            >
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-facebook"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-twitter"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-github"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-dribbble"></span
                                    ></a>
                                </li>
                            </ul>
                            <a
                                href="team-single.html"
                                target="_blank"
                                class="btn app-store-btn"
                                >View details</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div
                        class="single-team-member position-relative my-lg-3 my-md-3 my-sm-0"
                    >
                        <div class="team-image">
                            <img
                                src="img/team-6.jpg"
                                alt="Team Members"
                                class="img-fluid rounded"
                            />
                        </div>
                        <div
                            class="team-info text-white rounded d-flex flex-column align-items-center justify-content-center"
                        >
                            <h5 class="mb-0">Edna Mason</h5>
                            <h6>Senior Designer</h6>
                            <ul
                                class="list-inline team-social social-icon my-4 text-white"
                            >
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-facebook"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-twitter"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-github"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-dribbble"></span
                                    ></a>
                                </li>
                            </ul>
                            <a
                                href="team-single.html"
                                target="_blank"
                                class="btn app-store-btn"
                                >View details</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div
                        class="single-team-member position-relative my-lg-3 my-md-3 my-sm-0"
                    >
                        <div class="team-image">
                            <img
                                src="img/team-7.jpg"
                                alt="Team Members"
                                class="img-fluid rounded"
                            />
                        </div>
                        <div
                            class="team-info text-white rounded d-flex flex-column align-items-center justify-content-center"
                        >
                            <h5 class="mb-0">Edna Mason</h5>
                            <h6>Senior Designer</h6>
                            <ul
                                class="list-inline team-social social-icon my-4 text-white"
                            >
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-facebook"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-twitter"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-github"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"
                                        ><span class="ti-dribbble"></span
                                    ></a>
                                </li>
                            </ul>
                            <a
                                href="team-single.html"
                                target="_blank"
                                class="btn app-store-btn"
                                >View details</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "TeamMember",
};
</script>

<style>
</style>