<template>
    <section
        class="pt-100"
        style="background: #282447;"
    >
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-6 col-lg-5">
                    <div class="position-relative mt-lg-0 mt-md-0 mt-5 text-white">
                        <h1 class="text-white mb-10">
                            Servicios
                        </h1>
                        <h4 class="text-white mb-10">Soluciones de asignación de beneficios económicos. Fácil. Rápido. Seguro.</h4>
                        <p class="lead" style="line-height: 24px;">
                            Usa el poder de la plataforma Kupi para implementar tus estrategias de bienestar empresarial, fidelización de clientes y retención de talento tán rápido como desees de forma intuitiva, segura y 100% digital.
                        </p>
                        <a href="/contact" class="btn google-play-btn boton">SOLICITA UN DEMO</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-7">
                    <div class="offer-tag-wrap position-relative z-index">
                        <img
                            src="img/servicios/celular_kupi.png"
                            alt="app"
                            class="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "Banner",
};
</script>

<style scoped>
    .boton {
        color: #FFFFFF;
        background: #FF7777;
        border-color: #FF7777;
    }
</style>