<template>
    <div>
        <nav-bar />
        <div class="main">
            <Banner/>
            <Novedades/>
            <SiteFooter/>
        </div>
    </div>
</template>

<script>

import NavBar from "../commons/NavBar";
import SiteFooter from "../commons/SiteFooter";
import Banner from './Banner';
import Novedades from './Novedades';

export default {
    name: 'Kupilover',
    components: {
        NavBar,
        Banner,
        Novedades,
        SiteFooter
    },
}
</script>

<style>

</style>