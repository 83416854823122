<template>
   
    <section class="client-section pt-80" v-bind:class="isGray ? 'gray-light-bg' : ''">
        <div class="container">
            
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>{{title}}</h2>
                        <p class="lead">{{description}}</p>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-12">
                    <carousel class="owl-theme clients-carousel dot-indicator owl-loaded owl-drag"
                        :items="1"
                        :nav="false"
                        :autoplay="true"
                        :dots="true"
                        :loop="true"
                        :margin="15"
                        slideTransition="linear"
                        :autoplayTimeout="4000"
                        :autoplaySpeed="false"
                        :smartSpeed="6000"
                        :responsive="{
                            0: { items: 2 },
                            500: { items: 3 },
                            600: { items: 4 },
                            800: { items: 5 },
                            1200: { items: 6 },
                        }">
                        <div v-for="(img, index) in images" :key="index" class="item single-client">
                            <img
                                :src="img"
                                alt="client logo"
                                class="client-img"
                            />
                        </div>
                    </carousel>
                </div>
            </div>
            <div v-if="button !== ''" class="text-center mb-5">
                <router-link :to="href" class="btn solid-btn mt-2">{{button}}</router-link>
            </div>
            <div v-else class="mb-5"></div>
           
        </div>
    </section>
    

    
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
    name: "Customer",
    components: { carousel },
    props: {
        isGray: {
            type: Boolean,
            default: false,
        },
        paddingTop: {
            type: Boolean,
            default: true,
        },
        paddingBottom: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        button: {
            type: String,
            default: ''
        },
        href: {
            type: String,
            default: '#'
        },
        images: {
            default: [
                'img/client-5-color.png',
                'img/client-5-color.png',
                'img/client-5-color.png',
            ]
        }
    },
};
</script>

<style scoped>
    h2 {
        color: #6615de;
    }
</style>