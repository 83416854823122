<template>
    <div class="col-lg-6">
        <div class="section-heading text-center mb-2">
            <h5>{{title}}</h5>
        </div>
        <ul class="list-unstyled">
            <li v-for="(item, index) in items" :key="index" class="mb-2">
                <div class="d-flex align-items-center">
                <div>
                    <div class="badge badge-circle badge-primary mr-3">
                    <span class="ti-check"></span>
                    </div>
                </div>
                <div><p class="mb-0">{{item}}</p></div>
                </div>
            </li>
        </ul>
        <div class="text-center mb-5 mt-2">
            <a @click="showYouTubeModal" href="#" class="btn solid-btn mt-2">Beneficios</a>
        </div>
        <YTModal
            :url="href"
            v-if="showModal"
            @close="showModal = false"
        >
            This is my first modal
        </YTModal>
    </div>
</template>

<script>
import YTModal from "../../../components/YTModal";

export default {
    name: 'listBeneficios',
    components: { YTModal },
    data: function () {
        return {
            showModal: false,
        };
    },
    methods: {
        showYouTubeModal: function (e) {
            e.preventDefault();
            this.showModal = true;
        },
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        items: {
            default: []
        },
        href: {
            type: String,
            default: '#'
        }
    }

}
</script>

<style>

</style>