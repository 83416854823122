<template>
  <section
      class="pt-100 pb-100"
      style="background: #0D1E2B;"
  >
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-6 col-lg-6">
          <div class="position-relative mt-lg-0 mt-md-0 mt-5 text-white">
            <h1 class="text-white mb-10">{{ title }}</h1>
            <p class="lead" style="line-height: 24px;">{{ text1 }}</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div>
            <b-carousel
              v-model="slide"
              :interval="3000"
              controls
              indicators
              background="#0D1E2B"
              img-width="1024"
              img-height="480"
              style="text-shadow: 1px 1px 2px #333;"
              @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd"
            >
              <b-carousel-slide v-for="img in imagenesFiltradas" :key="img" :img-src="img.url"
              >
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue';
import Vue from 'vue';
Vue.component('b-carousel', BCarousel)
Vue.component('b-carousel-slide', BCarouselSlide)

export default {
    name: "Banner",
    props: {
        title: {type: String},
        subtitle: {type: String},
        text1: {type: String},
        text2: {type: String},
        img1: {type: String},
        img2: {type: String},
        img3: {type: String},
        img4: {type: String},
        img5: {type: String},
        img6: {type: String},
        img7: {type: String},
        img8: {type: String},
        img9: {type: String},
        img10: {type: String},
        img11: {type: String},
        img12: {type: String},
        img13: {type: String},
        img14: {type: String},
        img15: {type: String},
        img16: {type: String},
    },
    data() {
        return {
            slide: 0,
            sliding: null,
            imagenes: [
                { url: this.img1 },
                { url: this.img2 },
                { url: this.img3 },
                { url: this.img4 },
                { url: this.img5 },
                { url: this.img6 },
                { url: this.img7 },
                { url: this.img8 },
                { url: this.img9 },
                { url: this.img10 },
                { url: this.img11 },
                { url: this.img12 },
                { url: this.img13 },
                { url: this.img14 },
                { url: this.img15 },
                { url: this.img16 },
            ],
        };
    },
    computed: {
        imagenesFiltradas() {
            console.log(this.imagenes)
            // Filtra las imágenes con URLs no vacías
            return this.imagenes.filter(img => img && typeof img.url === 'string' && img.url.trim() !== '');
        },
    },
    methods: {
        onSlideStart () {
        this.sliding = true
        },

        onSlideEnd () {
        this.sliding = false
        },
    }
};
</script>

<style scoped>
    
</style>