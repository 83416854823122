
<template>
    <section class="background">
        <div class="container mt-5 mb-5" style="text-align: center;">
            <h1>Únete al newsletter</h1>
            <p>Regístrese aquí para recibir las últimas noticias y actualizaciones en Kupi.</p>
            <div style="display: flex; flex-direction: column;">
                <form
                    v-on:submit="submit"
                    method="post"
                    id="getQuoteFrm"
                    novalidate="true"
                    style="display: flex; flex-wrap: wrap;"
                    class="mb-2"
                >
                    <div class="input col-md-6">
                        <label>Nombre *</label>
                        <input
                            type="text"
                            name="name"
                            required="required"
                            class="form-control"
                        />
                    </div>
                    <div class="input col-md-6">
                        <label>Apellido *</label>
                        <input
                            type="text"
                            name="lastname"
                            required="required"
                            class="form-control"
                        />
                    </div>
                    <div class="input col-md-12">
                        <label>Correo *</label>
                        <input
                            type="text"
                            name="email"
                            required="required"
                            class="form-control"
                        />
                    </div>
                </form>
                <a href="#" class="btn google-play-btn boton">Suscribirme</a>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "NewsLetters",
};
</script>
<style scoped>
    .background {
        background-color: #282447;
        padding: 20px 10%;
        margin-bottom: 1px;
        margin-top: 50px;
    }

    .boton {
        color: #FFFFFF;
        background: #FF7777;
        border-color: #FF7777;
        width: 250px;
        align-self: center;
    }

    h1, h3 {
        color: #FF7777;
    }

    p {
        color: #FFFFFF;
    }

    input {
        margin: 5px;
        border-radius: 20px;
        padding: inherit
    }

    .input {
        display: grid;
        align-items: center;
        margin-top: 15px;
    }

    label {
        color: #FFFFFF;
        text-align: center;
        font-size: large;
        font-weight: 600;
    }
</style>