<template>
    <section>
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div v-if="imageLeft" class="col-md-6">
                    <div class="about-content-right">
                        <img
                            :src="img"
                            alt="about us"
                            class="img-fluid"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="about-content-left section-heading">
                        <h2 class="color-primary">
                            PLATAFORMA KUPI PARA <span>{{ title }}</span>
                        </h2>
                        <h5 class="color-primary">{{ subtitle }}</h5>
                        <p class="color-primary">{{ text1 }}</p>
                        <p class="color-primary">{{ text2 }}</p>
                        <a :href="route" class="btn google-play-btn boton">{{ textButton }}</a>
                    </div>
                </div>
                <div v-if="!imageLeft" class="col-md-6">
                    <div class="about-content-right">
                        <img
                            :src="img"
                            alt="about us"
                            class="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "Sections",
    props: {
        imageLeft: { type: Boolean, default: false },
        title: {type: String},
        span: {type: String},
        subtitle: {type: String},
        text1: {type: String},
        text2: {type: String},
        textButton: {type: String},
        route: {type: String, defualt: '#'},
        img: {type: String},
    }
};
</script>

<style scoped>

    .color-primary {
        color: #7613DD;
    }

    .color-primary  span {
        color: #FF7777;
        font-weight: 600;
        font-size: 35px;
    }

    .boton {
        color: #FFFFFF !important;
        background: #FF7777;
        border-color: #FF7777;
        border-radius: 15px;
        width: 250px;
    }

    .boton:hover {
        color: #FF7777 !important;
    }

    section {
        margin-bottom: 150px;
    }
</style>