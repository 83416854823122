<template>
    <section id="download" class="video-promo ptb-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-7">
                    <div class="download-content">
                        <h2>Download App Manage Your Business More Faster</h2>
                        <p>
                            Objectively deliver professional value with diverse
                            web-readiness. Collaboratively transition wireless
                            customer service without goal-oriented catalysts for
                            change. Collaboratively.
                        </p>
                        <p>
                            Progressively disseminate sustainable "outside the
                            box" thinking before end-to-end ideas. Dramatically
                            disintermediate resource maximizing action.
                        </p>
                        <div class="download-btn mt-4">
                            <a href="#" class="btn solid-btn mr-3"
                                ><span class="ti-android"></span> Google Play</a
                            >
                            &nbsp;
                            <a href="#" class="btn outline-btn"
                                ><span class="ti-apple"></span> App Store</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="download-img mt-lg-5 mt-md-5 mt-sm-0">
                        <img
                            src="img/image-14.png"
                            alt="download"
                            class="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "VideoPromo",
};
</script>

<style>
</style>