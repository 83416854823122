<template>
    <div>
        <nav-bar />
        <div class="main">
            <banner/>
            <!-- <videos url="https://www.youtube.com/watch?v=Qt5cRQhV70A&pp=ygULcXVlIGVzIGt1cGk%3D"/> -->
            <!-- <preferidos /> -->
            <Alcance/>
            <!-- <revolucion /> -->
            <!-- <section-page
                title="SERVICIOS TÉCNICOS"
                :cards="[
                    {title: 'Resolución de problemas', img: 'img/recursos/CASOS-DE-EXITO.png'},
                    {title: 'Instalaciones', img: 'img/recursos/WEBINAR.png'},
                    {title: 'Evaluaciones de líneas y equipos', img: 'img/recursos/ESTUDIO.png'},
                    {title: 'Capacitaciones', img: 'img/recursos/CASOS-DE-EXITO.png'},
                    {title: 'Desarrollo de proyectos', img: 'img/recursos/WEBINAR.png'},
                    {title: 'Planes de mantenimiento', img: 'img/recursos/ESTUDIO.png'},
                ]"
            /> -->
            <Servicios/>
            <SiteFooter/>
        </div>
    </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import Banner from "../../views/home/Banner";
// import Videos from "../home/Videos.vue";
import SiteFooter from '../../views/commons/SiteFooter';
// import SectionPage from "../home/SectionPage.vue";
import Alcance from '../home/Alcance.vue';
import Servicios from "./Servicios.vue";

export default {
    components: {
    NavBar,
    Banner,
    // Videos,
    Alcance,
    // SectionPage,
    SiteFooter,
    Servicios
},
};
</script>
