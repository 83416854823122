<template>
    <section
        class="download-section pt-100 background-img download-1-bg"
        
    >
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-7">
                    <div class="download-content text-white pb-100">
                        <h2 class="text-white">
                            Start Managing your apps business, more faster
                        </h2>
                        <p class="lead">
                            Objectively deliver professional value with diverse
                            web-readiness. Collaboratively transition wireless
                            customer service without goal-oriented catalysts for
                            change. Collaboratively.
                        </p>

                        <div class="download-btn">
                            <a href="#" class="btn google-play-btn mr-3"
                                ><span class="ti-android"></span> Google Play</a
                            >
                            &nbsp;
                            <a href="#" class="btn app-store-btn"
                                ><span class="ti-apple"></span> App Store</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="download-img d-flex align-items-end">
                        <img
                            src="img/app-hand-top.png"
                            alt="download"
                            class="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Download",
};
</script>

<style>
.download-1-bg {
    background: url("../../assets/img/app-hero-bg.jpg") no-repeat center center /
        cover !important;
}
</style>