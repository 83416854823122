<template>
  <div>
      <nav-bar />
      <div class="main">
          <Banner
            title="EMPAQUE"
            text1="Brindamos soporte técnico en empaques, máquinas empacadoras y materiales flexibles (papel, plástico, aluminio y laminados). "       
            img1="img/recursos/empaque1.jpg"        
            img2="img/recursos/empaque2.jpg"                       
            img3="img/recursos/empaque3.jpg"        
            img4="img/recursos/empaque4.jpg"        
            img5="img/recursos/empaque5.jpg"        
            img6="img/recursos/empaque6.jpg"        
            img7="img/recursos/empaque7.jpg"        
            img8="img/recursos/empaque8.jpg"        
            img9="img/recursos/empaque9.jpg"        
            img10="img/recursos/empaque10.jpg"        
            img11="img/recursos/empaque11.jpg"        
            img12="img/recursos/empaque12.jpg"        
            img13="img/recursos/empaque13.jpg"        
            img14="img/recursos/empaque14.jpg"        
            img15="img/recursos/empaque15.jpg"        
            img16="img/recursos/empaque16.jpg"                        
          />
          <sectionEmpaque
            title="Nuestro servicio:"
            subtitle1="•	Asesoría en el diseño y la selección de empaques."
            subtitle2="•	Evaluación, instalación, configuración y puesta en marcha de máquinas empacadoras."
            subtitle3="•	Desarrollo de productos innovadores y sustentables."
            subtitle4="•	Creación de estándares sostenibles en el tiempo."
            subtitle5="•	Entrenamiento en la operación de máquinas empacadoras."
            subtitle6="•	Resolución de problemas técnicos."
            subtitle7="•	Planes de mantenimiento."
            subtitle8="•	Suministro de repuestos."
            title3="Tipos de empaque:"
            subtitle9="•	Alimenticia: bebidas, confitería y productos frescos."
            title2="Aplicaciones en la industria:"
            subtitle10="•	Alimenticia: bebidas, confitería y productos frescos."
            subtitle11="•	Farmacéutica: tabletas, cápsulas y dispositivos médicos."
            subtitle12="•	Cosmética: lociones, cremas, champús, paños húmedos."
            subtitle13="•	Química: detergentes, artículos de limpieza y lubricantes."
            subtitle14="•	Electrónica: repuestos y tarjetas electrónicas."
            title4="Aplicaciones:"
            subtitle15="•	Sellado en caliente, frío, inducción y ultrasonido."
            subtitle16="•	Máquinas envolvedoras horizontales y envasadoras verticales FFS."
            />
      </div>
      <SiteFooter/>
  </div>
</template>

<script>

import NavBar from "../../commons/NavBar.vue";
import Banner from "../components/Banner.vue"
import SectionEmpaque from "../../Industrias/components/SectionEmpaque.vue";
import SiteFooter from '../../../views/commons/SiteFooter';

export default {
    name: 'Empaque',
    components: {
      NavBar,
      Banner,
      SectionEmpaque,
      SiteFooter
    },
}
</script>

<style scoped>
.video-fondo {
    position: relative;
    width: 50%;
    height: 50vh;
    overflow: hidden;
    left: 25%;
    margin-bottom: 80px;
  }

</style>