<template>
    <div>
        <nav-bar />
        <div class="main" style="margin-top: 80px;">
            <iframe 
                src="https://docs.google.com/gview?url=https://s3.amazonaws.com/app.kupi.s3/tratamientoDatos/Politica_Tratamiento_de_Datos_KUPI_2018.pdf&embedded=true"
                style="width:100%;
                height:700px;" 
                frameborder="0" >
            </iframe>
        </div>
    </div>
</template>

<script>
import NavBar from "../views/commons/NavBar.vue";
export default {
    name: "TratamientoDatos",
    components: {
        NavBar
    },
};
</script>