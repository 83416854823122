<template>
  <section class="hero-section hero-section-2 ptb-100">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-6 col-lg-6">
          <div class="hero-content-left ptb-100 text-white">
            <h1 class="text-white">
              <span>{{ title }}</span>
            </h1>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="hero-animation-img mt-5">
            <img
              class="img-fluid d-block"
              :src="img"
              alt="animation image"
              width="550"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Banner',
  props: {
    title: {
      default: ''
    },
    img: {
      default: ''
    }
  }
};
</script>

<style>
</style>