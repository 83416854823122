<template>
    <section
        class="testimonial-section ptb-100"
        v-bind:class="isGray ? 'gray-light-bg' : ''"
    >
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-md-6">
                    <div class="section-heading mb-5">
                        <h2>
                            Testimonios <br />que dicen los clientes
                        </h2>
                        <p class="lead">
                            Clientes y aliados satisfechos, es la mejor motivación para seguir creciendo.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 owl-custom-nav-parent">
                    <carousel
                        ref="oc"
                        :autoplay="true"
                        :dots="false"
                        class="owl-carousel owl-theme client-testimonial arrow-indicator owl-loaded owl-drag"
                        :loop="true"
                        :margin="30"
                        :nav="true"
                        :responsiveClass="true"
                        :autoplayHoverPause="true"
                        :lazyLoad="true"
                        :items="1"
                        :nav-text="navText"
                    >
                        <div v-for="({img, nombre, title, testimonio}, index) in testimonios" :key="index" class="item">
                            <div class="testimonial-quote-wrap">
                                <div class="media author-info mb-3">
                                    <div class="author-img mr-3">
                                        <img
                                            :src="img"
                                            alt="client"
                                            class="img-fluid rounded-circle"
                                        />
                                    </div>
                                    <div class="media-body">
                                        <h5 class="mb-0">{{nombre}}</h5>
                                        <span>{{title}}</span>
                                    </div>
                                </div>
                                <div class="client-say">
                                    <p>
                                        <img
                                            src="img/quote.png"
                                            alt="quote"
                                            class="img-fluid"
                                        />{{testimonio}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </carousel>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
    name: "Testimonial",
    components: { carousel },
    props: {
        isGray: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            navText: ["‹", "›"],
            testimonios: [
                {
                    nombre: 'Angélica Flor Trujillo de la Torre',
                    title: 'Gerente Fondea',
                    img: 'https://s3.amazonaws.com/app.kupi.s3/images/web/fondeadoras/fondea-logo.jpg',
                    testimonio: 'Un día mi jefe me comentó, que en una capacitación donde había estado, escucho a una persona exponer sobre una nueva unidad digital de negocio que se llamaba KUPI, y que sería bueno investigar sobre ella, yo me puse en la tarea de hacerlo y contacte la empresa con a través del Sr. Alberto, con quien nos colocamos de acuerdo y realizó la presentación a lo cual lleno de forma parcial nuestras expectativas, por tal motivo iniciamos la alianza en el año 2019, y poco a poco esta Herramienta llamada KUPIFONDEA ha sido una de las mejores opciones que cuenta Fondea para dar alcance a nivel nacional a nuestra base social, generando servicio, transparencia y solidez al momento de hacer uso del portafolio de convenios con que cuenta Kupi, como Gerente de Fondea, cuento con esta alianza para nuestra base social por su seriedad, responsabilidad y ante todo imagen corporativa para que nuestros asociados utilicen los servicios por medio de esta APP en los diferente aliados comerciales que tienen, generando así una excelente opción para Fondea.'
                },
            ]
        };
    },
};
</script>

<style >
.owl-custom-nav-parent .owl-carousel .owl-nav .owl-next:hover {
    color: #ffffff !important;
}
.owl-custom-nav-parent .owl-carousel .owl-nav .owl-prev:hover {
    color: #ffffff !important;
}
</style>