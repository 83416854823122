<template>
    <div>
        <nav-bar />
        <div class="main">
            <Banner/>
            <Beneficios/>
            <Contacts/>
            <Testimonial/>
            <Clients title="Fondeadores vinculados" :images="images"/>
            <SiteFooter/>
        </div>
    </div>
</template>

<script>

import NavBar from "../commons/NavBar";
import Banner from "../fondeadores/Banner"
import Beneficios from "../fondeadores/beneficios/Beneficios"
import SiteFooter from '../commons/SiteFooter';
import Contacts from '../commons/Contact';
import Testimonial from "../commons/Testimonial";
import Clients from '../commons/Clients.vue';

export default {
    name: 'Fondeadores',
    data: function(){
        return {
            images: [
                'https://s3.amazonaws.com/app.kupi.s3/images/web/fondeadoras/actuar-logo-300x171.png',
                'https://s3.amazonaws.com/app.kupi.s3/images/web/fondeadoras/codesa-logo-300x171.png',
                'https://s3.amazonaws.com/app.kupi.s3/images/web/fondeadoras/comfandi-logo-300x171.png',
                'https://s3.amazonaws.com/app.kupi.s3/images/web/fondeadoras/cooadams-logo-300x171.png',
                'https://s3.amazonaws.com/app.kupi.s3/images/web/fondeadoras/coobomberos-logo-300x171.png',
                'https://s3.amazonaws.com/app.kupi.s3/images/web/fondeadoras/cooperbase-logo-300x171.png'
            ]
        }
    },
    components: {
        NavBar,
        Banner,
        Beneficios,
        Contacts,
        Testimonial,
        Clients,
        SiteFooter
    },
}
</script>

<style>

</style>