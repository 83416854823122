<template>
    <section
        class="pt-100"
        style="background: #282447;"
    >
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-6 col-lg-6">
                    <div class="position-relative mt-lg-0 mt-md-0 mt-5 text-white">
                        <h1 class="text-white mb-10">
                            Industrias
                        </h1>
                        <h4 class="text-white mb-10">Personalización para todas las necesidades</h4>
                        <p class="lead" style="line-height: 24px;">
                            Agiliza la implementación de estrategias de bienestar, fidelización de clientes y activación comercial, al tiempo que haces parte de la lucha contra el crédito informal y aportas a mejorar los indicadores de inclusión financiera en Latinoamérica y el mundo.
                        </p>
                        <p class="lead" style="line-height: 24px;">
                            Las soluciones innovadoras de Kupi que se centran en mejorar la calidad de vida los usuarios, introducen cambios en las reglas de juego de las empresas e instituciones más grandes de la región.
                        </p>
                        <a href="/contact" class="btn google-play-btn boton">SOLICITA UN DEMO</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="offer-tag-wrap position-relative z-index">
                        <img
                            src="img/industrias/ejecutivos.png"
                            alt="app"
                            class="img-fluid"
                            style="border-radius: 30px;"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "Banner",
};
</script>

<style scoped>
    .boton {
        color: #FFFFFF;
        background: #FF7777;
        border-color: #FF7777;
    }
</style>