<template>
    <div>
        <nav-bar />
        <div class="main">
            <Banner/>
            <cifras />
            <sectores />
            <SiteFooter/>
        </div>
    </div>
</template>

<script>

import NavBar from "../../views/commons/NavBar";
import Banner from "../Industrias/Banner.vue"
import Cifras from "../Industrias/Cifras.vue"
import Sectores from "../Industrias/Sectores.vue"
import SiteFooter from '../../views/commons/SiteFooter';

export default {
    name: 'Industrias',
    components: {
        NavBar,
        Banner,
        Cifras,
        Sectores,
        SiteFooter
    },
}
</script>

<style scoped>
   
</style>