<template>
  <section id="about" class="about-us ptb-100 gray-light-bg">
    <div class="container">
      <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="section-heading text-center mb-5">
                    <h2>KupiLover</h2>
                    <!-- <p>Al ser parte de nuestra comunidad de aliados comerciales tendrás los siguientes beneﬁcios.</p> -->
                </div>
            </div>
        </div>
      <div class="row align-items-center justify-content-between">
        <p>{{descripcion}}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Beneficios",
  props: {
    descripcion: {
      default: ''
    }
  },
  data: function() {
    return  {
    }
  }
};
</script>

<style scoped>
  img {
    border-radius: 0px 0px 200px 0px
  }
</style>