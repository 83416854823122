<template>
  <section class="hero-section hero-section-2 ptb-100">
    <div class="circles">
      <div class="point animated-point-1"></div>
      <div class="point animated-point-2"></div>
      <div class="point animated-point-3"></div>
      <div class="point animated-point-4"></div>
      <div class="point animated-point-5"></div>
      <div class="point animated-point-6"></div>
      <div class="point animated-point-7"></div>
      <div class="point animated-point-8"></div>
      <div class="point animated-point-9"></div>
    </div>
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-6 col-lg-6">
          <div class="hero-content-left ptb-100 text-white">
            <h1 class="text-white">
              <span>Best way to connect with your customers</span>
            </h1>
            <p class="lead">
              Conveniently generate multifunctional markets and B2C vortals.
              Uniquely enable inexpensive materials rather than sticky products.
            </p>

            <a href="#" class="btn app-store-btn">Contact with us</a>
          </div>
        </div>
        <div class="col-md-6 col-lg-5">
          <div class="hero-animation-img">
            <img
              class="img-fluid d-block animation-one"
              src="img/hero-animation-04.svg"
              alt="animation image"
            />
            <img
              class="img-fluid d-none d-lg-block animation-two"
              src="img/hero-animation-01.svg"
              alt="animation image"
              width="120"
            />
            <img
              class="img-fluid d-none d-lg-block animation-three"
              src="img/hero-animation-02.svg"
              alt="animation image"
              width="120"
            />
            <img
              class="img-fluid d-none d-lg-block animation-four"
              src="img/hero-animation-03.svg"
              alt="animation image"
              width="230"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Banner",
};
</script>

<style>
</style>