<template>
    <section class="container mt-5 ptb-100">
        <div class="row justify-content-between align-items-center">
            <div class="col-md-6">
                <div class="mb-5 pl-5 pr-5 marca-agua" style="text-align: center;">
                  <img src="img/logo/imglogo.png" alt="Marca de agua">
                </div>
            </div>
            <div class="col-md-6">
                <div class="section-heading mb-5 pl-5 pr-5" style="text-align: center;">
                    <h2 style="color: #800000;">ACERCA DE NOSOTROS</h2>
                    <p class="lead" style="color: #0D1E2B;">Packing Support es una empresa prestadora de servicios en resolución de problemas, evaluación e instalación de líneas y equipos; desarrollo de productos innovadores y sustentables; planes de mantenimiento, estandarización y entrenamiento.</p>
                    <p class="lead" style="color: #0D1E2B;">Asimismo, le apuntamos a la eliminación de condiciones inseguras en las operaciones, a los reclamos de producto no conforme, las averías y los desperdicios que impactan directamente en sus indicadores de disponibilidad, rendimiento y calidad (OEE).</p>
                    <p class="lead" style="color: #0D1E2B;">Nuestra experiencia y conocimiento están enfocados activamente en garantizar un proceso productivo óptimo, acciones perdurables en el tiempo que reducirán el impacto ambiental y generarán mayor rentabilidad. </p>
                    <h5 class="lead" style="color: #800000; font-weight: bold;">¡Logre sus objetivos con nosotros! Estamos disponibles para Colombia y Latinoamérica.</h5>                
                  </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "MisionVision",
};
</script>
<style scoped>
.app {
  position: relative;
}
.marca-agua {
  align-content: center;
  opacity: 0.5;
  right: 10px;
  pointer-events: none;
}
@media (max-width: 1100px) {
  .marca-agua img{
   width: 180%;
  }
}
@media (max-width: 750px) {
  .marca-agua img{
   width: 90%;
  }
}
</style>