<template>
    <section
        class="hero-section"
        style="background: url('https://kupi.41custom.com/wp-content/uploads/2022/03/banner-fondeadores.jpg') no-repeat center center / cover;">
        <div class="video-section-wrap">
            <div class="background-video-overly ptb-100">
                <div
                    id="heroPlayer"
                    class="player"
                    data-property="{videoURL:'https://www.youtube.com/watch?v=gOqlwlQjVis',containment:'.video-section-wrap', quality:'highres', autoPlay:true, showControls: false, startAt:0, mute:true, opacity: 1}"
                ></div>
                <div class="container">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-md-8 col-lg-7">
                            <div class="hero-content-left text-white text-center mt-5 ptb-100">
                                <h1 class="text-white">Fondeadores</h1>
                                <p class="lead">
                                    Éstas compañías ya utilizan KUPI para otrogar y seguir sus beneficios
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import $ from "jquery";

export default {
    name: "Banner",
    mounted() {
        // $("#heroPlayer").YTPlayer();
        this.player = $("#heroPlayer").YTPlayer();
        setTimeout(() => {
            this.player.YTPlayer();
        }, 2000);
    },
};
</script>

<style scoped>
    h1 {
        font-weight: 800 !important;
    }
</style>