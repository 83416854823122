<template>
    <div>
        <nav-bar />
        <div class="main">
            <Banner/>
            <section-page
                title="Casos de éxito, Webinars y Estudios"
                :cards="[
                    {title: 'Caso de éxito', img: 'img/recursos/CASOS-DE-EXITO.png'},
                    {title: 'Webinar', img: 'img/recursos/WEBINAR.png'},
                    {title: 'Estudio', img: 'img/recursos/ESTUDIO.png'},
                ]"
            />
            <section-page 
                title="Blog"
                description="Mantengase al día con la información más relevante sobre la industria fintech, innovaciones, reportes, noticias y más."
                :cards="[
                    {title: 'ENTRADA 1', img: 'img/recursos/BLOG-1.png'},
                    {title: 'ENTRADA 2', img: 'img/recursos/BLOG-2.png'},
                    {title: 'ENTRADA 3', img: 'img/recursos/BLOG-3.png'},
                    {title: 'ENTRADA 4', img: 'img/recursos/BLOG-4.png'},
                    {title: 'ENTRADA 5', img: 'img/recursos/BLOG-5.png'},
                    {title: 'ENTRADA 6', img: 'img/recursos/BLOG-6.png'},
                ]"
            />
            <news-letters />
            <SiteFooter/>
        </div>
    </div>
</template>

<script>

import NavBar from "../../views/commons/NavBar";
import Banner from "./Banner.vue"
import SectionPage from "./SectionPage.vue";
import NewsLetters from '../../views/commons/NewsLetters.vue';
import SiteFooter from '../../views/commons/SiteFooter';

export default {
    name: 'Recursos',
    components: {
        NavBar,
        Banner,
        SectionPage,
        NewsLetters,
        SiteFooter
    },
}
</script>

<style>

</style>