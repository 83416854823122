<template>
    <section class="pt-80">
        <div class="container">
            <div class="section-heading text-center">
                <h2>{{ title }}</h2>
                <p>{{ description }}</p>
            </div>
            <div >
                <div class="wrapper ml-5 mr-5">
                    <card-recurso v-for="(card, index) in cards" :key="index"
                        :title="card.title"
                        :img="card.img"
                    />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import CardRecurso from '../Recursos/CardRecurso.vue';

export default {
    name: "SectionPage",
    components: {
        CardRecurso,
    },
    props: {
        title: {type: String},
        description: {type: String},
        cards: {default: []}
    }
};
</script>
<style scoped>
    h2 {
        color: #7613DD;
    }
    p {
        width: 500px;
        display: inline-flex;
    }
    .wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        justify-content: center;
    }
</style>