<template>
    <div class="overflow-hidden">
        <section id="pricing" class="package-section background-shape-right ptb-80">
            <div class="container">
                <div class="row justify-content-center">
                    <div v-for="(campana, index) in campanas" :key="index" class="col-lg-4 col-md">
                        <div class="card text-center single-pricing-pack mb-5">
                            <img :src="campana.img" alt="novedad img" class="img-fluid"/>
                            <div class="card-header py-4 border-0 pricing-header div-size">
                                <div class="h4 text-center mb-0">
                                    <span class="price font-weight-bolder">{{campana.titulo}}</span>
                                </div>
                            </div>
                            <div class="card-body ml-3 mr-3">
                                <div>
                                  <p class="center-content ">{{campana.cuerpo}}</p>
                                </div>
                                <a :href="campana.urlInfo" class="btn solid-btn mb-3" target="_blank">Conoce más</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import axios from 'axios';

export default {
  name: "Novedades",
  isGray: {
    type: Boolean,
    default: false,
  },
  isPrimary: {
    type: Boolean,
    default: false,
  },
  mounted() {
    this.getCampanas()
  },
  data: function() {
    return {
      campanas: [],
      novedades: [
        {
          img: "https://kupi.com.co/wp-content/uploads/2022/05/ALIADO.jpg",
          title: "KUPI COMO ALIADO ESTRATÉGICO",
          description: 'El poder de Kupi como el aliado estratégico que necesitas para generar nuevos clientes',
          url: "/genericVIew/1"
        }
      ]
    }
  },
  methods: {
    getCampanas () {
      axios.post(`https://kupi.com.co/ws3/public/campanas`)
      .then(res => {
        this.campanas = res.data
        console.log(this.campanas)
      })
      .catch(err => {
        console.error(err)
      })
    }
  }
};
</script>

<style scoped>
    .section-heading h2 {
        color: #6615de;
    }

    .single-pricing-pack:hover {
        box-shadow: 0 1rem 3rem rgba(31, 45, 61, .125) !important;
        transform: translate(0, 5px);
    }

    .div-size{
        height: 100px;
        display: flex;
        align-items: center;
    }

    .center-content{
        height: 150px;
        display: flex;
        align-items: center;
    }

    img {
        border-radius: 10px 10px 0px 0px;
    }
</style>