<template>
  <section class="ptb-100">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-md-6">
          <div class="section-heading">
            <h2 class="color" style="text-align: center; padding-bottom: 20px;">{{ title }}</h2>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle1 }}</h5>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle2 }}</h5>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle3 }}</h5>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle4 }}</h5>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle5 }}</h5>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle6 }}</h5>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle7 }}</h5>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle8 }}</h5>
          </div>
        </div>
        <div class="col-md-6">
          <div class="section-heading">
            <h2 class="color" style="text-align: center; padding-bottom: 20px;">{{ title2 }}</h2>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle10 }}</h5>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle11 }}</h5>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle12 }}</h5>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle13 }}</h5>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle14 }}</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-md-6">
          <div class="section-heading">
            <h2 class="color" style="text-align: center; padding: 40px 0 20px;">{{ title4 }}</h2>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle15 }}</h5>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle16 }}</h5>
          </div>
        </div>
        <div class="col-md-6">
          <div class="section-heading">
            <h2 class="color" style="text-align: center; padding: 40px 0 20px;">{{ title3 }}</h2>
            <h5 class="color" style="padding-left: 20px;">{{ subtitle9 }}</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Sections",
  props: {
      imageLeft: { type: Boolean, default: false },
      title: {type: String},
      title2: {type: String},
      title3: {type: String},
      title4: {type: String},
      span: {type: String},
      subtitle1: {type: String},
      subtitle2: {type: String},
      subtitle3: {type: String},
      subtitle4: {type: String},
      subtitle5: {type: String},
      subtitle6: {type: String},
      subtitle7: {type: String},
      subtitle8: {type: String},
      subtitle9: {type: String},
      subtitle10: {type: String},
      subtitle11: {type: String},
      subtitle12: {type: String},
      subtitle13: {type: String},
      subtitle14: {type: String},
      subtitle15: {type: String},
      subtitle16: {type: String},
      img: {type: String},
  }
};
</script>

<style scoped>

  .color {
      color: #0D1E2B;
  }
</style>