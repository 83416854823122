<template>
    <section class="client-section pt-50" v-bind:class="isGray ? 'gray-light-bg' : ''">
        <div class="container">
            <div class="wrapper ml-5">
                <card
                    title="SECTOR SOLIDARIO"
                    descripcion="Acelera la transformación digital y lucha contra el crédito informal."
                    img="img/industrias/apps.png"
                    route="/economia-solidaria"
                    :features="[
                        'Bonos de múltiple clases',
                        'Subsidios y auxilios',
                        'Cupos de crédito',
                        'Fidelización de asociados',
                        'Retención de talento',
                        'Viáticos',
                    ]"
                />
                <card
                    title="CAJAS DE COMPENSACIÓN"
                    descripcion="Aumenta el alcance de las alianzas y el bienestar de los afiliados."
                    img="img/industrias/familia.png"
                    route="/cajas-compensacion"
                    :features="[
                        'Bonos de múltiples clases',
                        'Subsidios y auxilios',
                        'Retención de talento',
                        'Viáticos',
                    ]"
                />
                <card
                    title="EMPRESAS"
                    descripcion="Ejecuta estrategias de bienestar y felicidad laboral."
                    img="img/industrias/celebracion-oficina.png"
                    route="/empresas"
                    :features="[
                        'Bonos de múltiple clases',
                        'Subsidios y auxilios',
                        'Cupos de crédito',
                        'Fidelización de clientes',
                        'Retención de talento',
                        'Viáticos',
                    ]"
                />
                <card
                    title="COMERCIOS ALIADOS"
                    descripcion="Aumenta los ingresos de tu negocio e impulsa tu marca."
                    img="img/industrias/venta.png"
                    route="/comercios"
                    :features="[
                        'Agrupación de convenios',
                        'Facturación semanal',
                        'Campañas especiales',
                        'Fidelización de clientes',
                        'Retención de talento'
                    ]"
                />
                <card
                    title="GOBIERNO"
                    descripcion="Aumenta el alcance de las alianzas y el bienestar de los afiliados."
                    img="img/industrias/politico.png"
                    :features="[
                        'Bonos de múltiples clases',
                        'Subsidios y auxilios',
                        'Retención de talento',
                        'Viáticos',
                    ]"
                />
            </div>
        </div>
    </section>
</template>
<script>
import Card from '../Industrias/Card.vue';

export default {
    name: "Sectores",
    components: {
        Card,
    }
};
</script>
<style scoped>
    .wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        justify-content: center;
    }
</style>