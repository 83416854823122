<template>
  <div>
    <nav-bar />
    <div class="main">
      <Banner
          title="ETIQUETADO"
          text1="Somos expertos en dispensado de etiquetas de seguridad, de identificación de producto y termoencogibles para aplicaciones en empaques flexibles, botellas, cajas, tapas, tarros, latas, bolsas, bandejas, entre otros."       
          img1="img/recursos/etiqueta1.jpg"        
          img2="img/recursos/etiqueta2.jpg"        
          img3="img/recursos/etiqueta3.jpg"        
          img4="img/recursos/etiqueta4.jpg"        
          img5="img/recursos/etiqueta5.jpg"                       
          img6="img/recursos/etiqueta6.jpg"                       
          img7="img/recursos/etiqueta7.jpg"                       
          img8="img/recursos/etiqueta8.jpg"                       
          img9="img/recursos/etiqueta9.jpg"                       
      />
      <sectionRobotica
        title="Nuestro servicio:"
        subtitle1="•	Evaluación, instalación, configuración y puesta en marcha de máquinas etiquetadoras."
        subtitle2="•	Creación de estándares sostenibles en el tiempo."
        subtitle3="•	Entrenamiento en la operación de máquinas etiquetadoras."
        subtitle4="•	Resolución de problemas técnicos."
        subtitle5="•	Planes de mantenimiento."
        subtitle6="•	Suministro de repuestos."
        title2="Aplicaciones en la industria:"
        subtitle21="•	Manufacturera."
        subtitle23="•	Alimenticia."
        subtitle24="•	Farmacéutica."
        subtitle27="•	Química."
        subtitle26="•	Logística."
      />
    </div>
    <SiteFooter/>
  </div>
</template>

<script>

import NavBar from "../../commons/NavBar.vue";
import Banner from "../../Industrias/components/Banner.vue"
import SectionRobotica from "../../Industrias/components/SectionRobotica.vue";
import SiteFooter from '../../../views/commons/SiteFooter';



export default {
    name: 'Robotica',
    components: {
    NavBar,
    Banner,
    SectionRobotica,
    SiteFooter
},
}
</script>