<template>
  <section id="about" class="about-us pt-100 gray-light-bg">
    <div class="container">
      <div class="row justify-content-center">
          <div class="col-md-8">
              <div class="section-heading text-center mb-5">
                  <h2>Beneficios</h2>
              </div>
          </div>
      </div>
      <BeneficioItem v-for="({direccion, beneficio}, index) in beneficios" :key="index" :imgLeft="direccion" :beneficio="beneficio"/>
    </div>
  </section>
</template>

<script>
import BeneficioItem from '../../../views/fondeadores/beneficios/BeneficioItem';

export default {
    name: "Beneficios",
    data: function() {
      return {
        beneficios: [
          {
            beneficio: {
              title: 'Sector solidario',
              img: 'https://s3.amazonaws.com/app.kupi.s3/images/web/fondeadoras/Sector-solidario-imagen.jpg',
              href: 'https://www.youtube.com/watch?v=kc3lScRfAd4',
              items: [
                'Bienestar para tus asociados y colaboradores de manera rápida, ágil y segura.',
                'Asignación de créditos o bonos digitales completamente parametrizables, consumibles en nuestra amplia red comercial o para retiro de efectivo gracias a nuestro convenio con Súper Giros.',
                'Agiliza la colocación de créditos brindando calidad de vida a tus asociados.'
              ]
            },
            direccion: true
          },
          {
            beneficio: {
              title: 'Cajas de compensación',
              img: 'https://s3.amazonaws.com/app.kupi.s3/images/web/fondeadoras/cajas-de-compensacion-imagen.jpg',
              href: 'https://www.youtube.com/watch?v=xjXze-13PtU',
              items: [
                'Asistencia en la entrega de subsidios y créditos a aﬁliados de forma segura y eﬁciente.',
                'Beneﬁcios totalmente customizables a través de nuestra plataforma.',
                'Descuentos y promociones especiales para sus asociados.',
                'Aumento de cobertura y mejoramiento de la calidad de vida de tus aﬁliados.',
                'Tus aﬁliados tendrán acceso fácil, rápido y ágil a sus productos y servicios.'
              ]
            },
            direccion: false
          },
          {
            beneficio: {
              title: 'Gobierno',
              img: 'https://s3.amazonaws.com/app.kupi.s3/images/web/fondeadoras/gobierno-imagen.jpg',
              href: 'https://www.youtube.com/watch?v=-0zQxsDaTe4',
              items: [
                'A través de los bonos Kupi, te brindarán subsidios de manera conﬁable, accesible, customizable y fácil, que te permitirá reaccionar rápidamente ante las adversidades o responsabilidades de asignaciones.',
                'Facilidad para direccionar el gasto y asignarlo a las categorías especíﬁcas dentro de nuestra gran red comercial: ropa, calzado, salud, vehículos, belleza, entre otros.',
                'Los usuarios Kupi recibirán descuentos y promociones especiales.',
                'Trazabilidad de la información que te permitirá hacer una mejor gestión en la entrega e informes de subsidios.',
                'Acceso para usuarios Kupi sin importar si cuentan o no con Internet o smartphone.',
                'Red de entrega de efectivo para los usuarios Kupi.'
              ]
            },
            direccion: true
          },
          {
            beneficio: {
              title: 'Empresas',
              img: 'https://s3.amazonaws.com/app.kupi.s3/images/web/fondeadoras/empresas-imagen.jpg',
              href: 'https://www.youtube.com/watch?v=-0zQxsDaTe4',
              items: [
                'Cupos de crédito y bonos (con beneﬁcios tributarios o no de acuerdo con la naturaleza, proporcionalidad y causalidad) que podrás otorgar a tu equipo de trabajo o usar para apoyar estrategias comerciales de tus clientes o prospectos.',
                'No se requiere un prepago inicial de los bonos que se entreguen a través de la plataforma para compras en comercios.',
                'Eﬁciencia en los procesos de gestión de subsidios y/o bonos.',
                'Posibilidad de generar ventas para servicios o productos.',
                'Datos sobre el comportamiento de consumo de los beneﬁciarios.'
              ]
            },
            direccion: false
          },
        ]
      }
    },
    comments: {
        BeneficioItem
    },
    components: { BeneficioItem }
};
</script>

<style scoped>
  
</style>