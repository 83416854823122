<template>
    <section class="promo-section ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>
                            Why small business owners <br />love AppCo?
                        </h2>
                        <p class="lead">
                            Following reasons show advantages of adding AppCo to
                            your lead pages, demos and checkouts
                        </p>
                    </div>
                </div>
            </div>
            <div class="row equal">
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-vector text-white"></span>
                        </div>
                        <h5>Clean Design</h5>
                        <p>
                            Increase sales by showing true dynamics of your
                            website.
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-lock text-white"></span>
                        </div>
                        <h5>Secure Data</h5>
                        <p>
                            Build your online store’s trust using Social Proof
                            &amp; Urgency.
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-eye text-white"></span>
                        </div>
                        <h5>Retina Ready</h5>
                        <p>
                            Realize importance of social proof in customer’s
                            purchase decision.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "Promo",
};
</script>