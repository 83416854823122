<template>
  <div>
    <nav-bar />
    <div class="main">
      <Banner :title="campanas.titulo" :img="campanas.img"/>
      <Beneficios :descripcion="campanas.descripcion"/>
      <SiteFooter/>
    </div>
  </div>
</template>

<script>
import NavBar from '../commons/NavBar'
import SiteFooter from '../commons/SiteFooter';
import Beneficios from "../../views/genericView/Beneficios"
import Banner from './Banner'
import axios from 'axios';

export default {
  name: 'GenericView',
  components: {
      NavBar,
      Banner,
      Beneficios,
      SiteFooter
  },
  mounted() {
    this.getCampanas()
  },
  data: function() {
    return {
      campanas: [],
    }
  },
  methods: {
    getCampanas () {
      axios.post(`https://kupi.com.co/ws3/public/campanas`,
      {
        id: this.$route.params.id
      })
      .then(res => {
        this.campanas = res.data
        console.log(this.campanas)
      })
      .catch(err => {
        console.error(err)
      })
    }
  }
}
</script>

<style>

</style>