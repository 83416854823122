<template>
    <div>
        <nav-bar />
        <div class="main">
            <section class="background" style="padding-top: 100px;">
                <div class="container">
                    <h1 style="text-align: center;">CONTÁCTA CON NOSOTROS</h1>
                    <div class="row align-items-center justify-content-between">
                        <div
                            class="col-12 pb-3 message-box d-done"
                            v-bind:class="{
                                'd-none': !isSuccess && !hasError,
                                'd-block': isSuccess || hasError,
                            }"
                        >
                            <div
                                class="alert"
                                v-bind:class="{
                                    'alert-danger': hasError,
                                    'alert-success': isSuccess,
                                }"
                            >
                                {{ alertText }}
                            </div>
                        </div>
                        <div class="col-md-5">
                            <h1>SOLICITA UNA DEMOSTRACIÓN</h1>
                            <p>Asignaciones intuitivas, seguras y 100% digitales con Kupi</p>
                            <h3 class="my-5">¿Por qué Kupi?</h3>
                            <ul class="list-unstyled">
                                <li v-for="(item, index) in items" :key="index" class="py-2">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <div class="badge badge-circle badge-primary mr-3" style="background-color: #FF7777">
                                                <span class="ti-check"></span>
                                            </div>
                                        </div>
                                        <div><p class="mb-0">{{ item }}</p></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-7">
                            <div style="display: flex; flex-direction: column;">
                                <form
                                    v-on:submit="submit"
                                    id="contactForm"
                                    method="post"
                                    novalidate="true"
                                    style="display: flex; flex-wrap: wrap; justify-content: center"
                                    class="mb-2"
                                >
                                    <div class="input col-md-6">
                                        <label>Nombre *</label>
                                        <input
                                            type="text"
                                            v-model="contactForm.name"
                                            name="name"
                                            required="required"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="input col-md-6">
                                        <label>Apellido *</label>
                                        <input
                                            type="text"
                                            v-model="contactForm.lastname"
                                            name="lastname"
                                            required="required"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="input col-md-6">
                                        <label>Posición *</label>
                                        <input
                                            type="text"
                                            v-model="contactForm.position"
                                            name="position"
                                            required="required"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="input col-md-6">
                                        <label>Correo *</label>
                                        <input
                                            type="text"
                                            v-model="contactForm.email"
                                            name="email"
                                            required="required"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="input col-md-6">
                                        <label>Entidad *</label>
                                        <input
                                            type="text"
                                            v-model="contactForm.company"
                                            name="company"
                                            required="required"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="input col-md-6">
                                        <label>Teléfono *</label>
                                        <input
                                            type="text" 
                                            v-model="contactForm.phone"
                                            name="phone"
                                            required="required"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="input col-md-6">
                                        <label>Ciudad *</label>
                                        <input
                                            type="text"
                                            v-model="contactForm.city"
                                            name="city"
                                            required="required"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="input col-md-6">
                                        <label>Número de asociados *</label>
                                        <input
                                            type="text"
                                            v-model="contactForm.team"
                                            name="team"
                                            required="required"
                                            class="form-control"
                                        />
                                    </div>
                                    <p style="text-align: center; font-size: smaller;">
                                        Kupi necesita la información de contacto que nos proporciona para comunicarnos con usted acerca de nuestros productos y servicios. Puede darse de baja de estas comunicaciones en cualquier momento. Para obtener información sobre cómo darse de baja, así como nuestras prácticas de privacidad y el compromiso de proteger su privacidad, consulte nuestra <span style="color: #FF7777;">Política de privacidad.</span>
                                    </p>
                                    <button type="submit" class="btn google-play-btn boton" 
                                        style="pointer-events: all; cursor: pointer">Enviar</button>
                                </form>
                                <p class="form-message"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SiteFooter/>
        </div>
    </div>
</template>
<script>

import axios from 'axios';
import NavBar from "../../views/commons/NavBar";
import SiteFooter from '../../views/commons/SiteFooter';

export default {
    name: "Demostracion",
    components: {
        NavBar,
        SiteFooter
    },
    data() {
        return {
            items: [
                'Pagas exactamente lo que tus asociados consumen.',
                'Dile adios a los eternos procesos tradicionales.',
                'Sigue en tiempo real el comportamiento de los consumos'
            ],
            contactForm: {
                name: '',
                lastname: '',
                position: '',
                email: '',
                company: '',
                phone: '',
                city: '',
                team: ''
            },
            alertText: "",
            hasError: false,
            isSuccess: false,
        }
    },
    methods:{
        submit: function (e) {
            e.preventDefault();
            axios
                .post('https://kupi.com.co/ws3/public/mailweb', this.contactForm)
                .then(res => {
                    console.log(res.data)
                    if (res.status === 200) {
                        this.alertText = res.data.message;
                        this.hasError = false;
                        this.isSuccess = true;
                    } else {
                        this.alertText = res.data.message;
                        this.hasError = true;
                        this.isSuccess = false;
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
    }
};
</script>
<style scoped>
    .background {
        background-color: #282447;
        padding: 20px 10%;
        margin-bottom: 1px;
    }

    .boton {
        color: #FFFFFF;
        background: #FF7777;
        border-color: #FF7777;
        width: 250px;
        align-self: center;
    }

    h1, h3 {
        color: #FF7777;
    }

    p {
        color: #FFFFFF;
    }

    input {
        margin: 5px;
        border-radius: 20px;
        padding: inherit
    }

    .input {
        display: grid;
        align-items: center;
    }

    label {
        color: #FFFFFF;
        text-align: center;
        font-size: large;
        font-weight: 600;
    }
</style>