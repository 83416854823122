<template>
    <div class="single-team-member position-relative circle empresa">
        <div class="team-image">
            <img
                :src="empresa.urlImagen"
                alt="Team Members"
                class="img-fluid rounded-circle"
            />
        </div>
        <div class="team-info text-white d-flex flex-column align-items-center justify-content-center rounded-circle">
            <h6>{{ empresa.nomEmpresa }}</h6>
            <h6 v-if="empresa.dirEmpresa !== ''" class="font-min">{{ empresa.dirEmpresa }}</h6>
            <h6 v-if="empresa.telEmpresa !== ''" class="font-min">{{ empresa.telEmpresa }}</h6>
            <ul class="list-inline team-social social-icon mt-2 text-white">
                <li v-if="empresa.whatsapp !== ''" class="list-inline-item">
                    <a :href="'https://api.whatsapp.com/send/?phone=' + empresa.whatsapp" title="Whatsapp" target="_blanck"><span class="ti-mobile"></span></a>
                </li>
                <li v-if="empresa.urlWeb !== ''" class="list-inline-item">
                    <a :href="empresa.urlWeb" title="Sitio web" target="_blanck"><span class="ti-world"></span></a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "Empresa",
    props: {
        empresa: {
            codEmpresa: {default: ''},
            dirEmpresa: {default: ''},
            nomEmpresa: {default: ''},
            telEmpresa: {default: ''},
            urlImagen: {default: ''},
            urlWeb: {default: ''},
            whatsapp: {default: ''},
        },
        empresa2: {}
    },
};
</script>

<style scoped>
    .p-25 {
        padding: 25px;
    }

    .empresa {
        margin-left: auto;
        margin-right: auto;
    }

    .empresa h6 {
        width: 80%;
        text-align: center;
    }

    .circle {
        width: 200px;
        height: 200px;
        margin-bottom: 50px;
    }

    .font-min {
        font-size: 12px;
    }
</style>