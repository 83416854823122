<template>
    <div>
        <nav-bar />
        <div class="main">
            <Banner/>
            <p class="text">Kupi proporciona productos y servicios que se pueden usar en entornos SaaS o como una aplicación móvil descargable.</p>
            <sections
                title="FONDEADORES"
                subtitle="Kupi supera los límites: La plataforma digital que te permite asignar beneficios económicos para revolucionar la experiencia de asociados, afiliados y colaboradores."
                text1="Kupi lleva la asignación de beneficios a otro nivel de agilidad, sencillez y seguridad en una sola plataforma."
                text2="Minimiza el esfuerzo eliminando procesos aburridos, reduce la huella de carbono al eliminar plásticos y papel y ofrece reportes de consumo en tiempo real."
                text-button="MÁS INFO"
                img="img/servicios/PLATAFORMA-FONDEADOR.png"
                route="/contact"
            />
            <sections
                title="COMERCIOS"
                subtitle="Kupi apoya el comercio local: La forma de pago que te permite aumentar las ventas de productos y servicios."
                text1="Mejora el alcance de tus esfuerzos comerciales, cuando eres Comercio Aliado de Kupi también eres aliado de nuestras entidades fondeadoras."
                text2="Un ciclo de facturación que te te saca de aprietos, relacionamiento comercial B2B y una plataforma amigable para procesar pagos."
                text-button="MÁS INFO"
                img="img/servicios/PLATAFORMA-COMERCIOS.png"
                image-left
                route="/contact"
            />

            <sections
                title="USUARIOS"
                subtitle="Kupi tiene una App Móvil para que los usuarios vivan una experiencia de canje fácil, rápida y segura."
                text1="App Kupi está diseñada para brindar acceso inmedito a la gestión de las asignaciones económicas."
                text2="Encontrar comercios aliados por ubicación geográfica y por categorías es simple. Además, visualización de promociones, nuevos comercios aliados e histórico de compras."
                text-button="MÁS INFO"
                img="img/servicios/CELULAR_2.png"
                route="/contact"
            />
            <SiteFooter/>
        </div>
    </div>
</template>

<script>

import NavBar from "../../views/commons/NavBar";
import Banner from "../Servicios/Banner.vue"
import Sections from "../Servicios/Sections.vue"
import SiteFooter from '../../views/commons/SiteFooter';

export default {
    name: 'Servicios',
    components: {
        NavBar,
        Banner,
        Sections,
        SiteFooter
    },
}
</script>

<style scoped>
    .text {
        color: #7613DD;
        text-align: center;
        font-weight: 700;
        font-size: large;
        margin-top: 20px;
        margin-bottom: 50px;
    }
</style>