var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"client-section pt-50",class:_vm.isGray ? 'gray-light-bg' : ''},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"wrapper ml-5"},[_c('card',{attrs:{"title":"SECTOR SOLIDARIO","descripcion":"Acelera la transformación digital y lucha contra el crédito informal.","img":"img/industrias/apps.png","route":"/economia-solidaria","features":[
                    'Bonos de múltiple clases',
                    'Subsidios y auxilios',
                    'Cupos de crédito',
                    'Fidelización de asociados',
                    'Retención de talento',
                    'Viáticos' ]}}),_c('card',{attrs:{"title":"CAJAS DE COMPENSACIÓN","descripcion":"Aumenta el alcance de las alianzas y el bienestar de los afiliados.","img":"img/industrias/familia.png","route":"/cajas-compensacion","features":[
                    'Bonos de múltiples clases',
                    'Subsidios y auxilios',
                    'Retención de talento',
                    'Viáticos' ]}}),_c('card',{attrs:{"title":"EMPRESAS","descripcion":"Ejecuta estrategias de bienestar y felicidad laboral.","img":"img/industrias/celebracion-oficina.png","route":"/empresas","features":[
                    'Bonos de múltiple clases',
                    'Subsidios y auxilios',
                    'Cupos de crédito',
                    'Fidelización de clientes',
                    'Retención de talento',
                    'Viáticos' ]}}),_c('card',{attrs:{"title":"COMERCIOS ALIADOS","descripcion":"Aumenta los ingresos de tu negocio e impulsa tu marca.","img":"img/industrias/venta.png","route":"/comercios","features":[
                    'Agrupación de convenios',
                    'Facturación semanal',
                    'Campañas especiales',
                    'Fidelización de clientes',
                    'Retención de talento'
                ]}}),_c('card',{attrs:{"title":"GOBIERNO","descripcion":"Aumenta el alcance de las alianzas y el bienestar de los afiliados.","img":"img/industrias/politico.png","features":[
                    'Bonos de múltiples clases',
                    'Subsidios y auxilios',
                    'Retención de talento',
                    'Viáticos' ]}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }