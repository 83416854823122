<template>
  <div>
    <nav-bar />
    <div class="main">
      <mision-vision />
      <SiteFooter/>
    </div>
  </div>
</template>

<script>

import NavBar from "../../views/commons/NavBar";
import MisionVision from "../Acerca/MisionVision.vue";
import SiteFooter from '../../views/commons/SiteFooter';

export default {
  name: 'Acerca',
  components: {
    NavBar,
    MisionVision,
    SiteFooter,
  },
}
</script>