var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav-bar'),_c('div',{staticClass:"main"},[_c('Banner'),_c('section-page',{attrs:{"title":"Casos de éxito, Webinars y Estudios","cards":[
                {title: 'Caso de éxito', img: 'img/recursos/CASOS-DE-EXITO.png'},
                {title: 'Webinar', img: 'img/recursos/WEBINAR.png'},
                {title: 'Estudio', img: 'img/recursos/ESTUDIO.png'} ]}}),_c('section-page',{attrs:{"title":"Blog","description":"Mantengase al día con la información más relevante sobre la industria fintech, innovaciones, reportes, noticias y más.","cards":[
                {title: 'ENTRADA 1', img: 'img/recursos/BLOG-1.png'},
                {title: 'ENTRADA 2', img: 'img/recursos/BLOG-2.png'},
                {title: 'ENTRADA 3', img: 'img/recursos/BLOG-3.png'},
                {title: 'ENTRADA 4', img: 'img/recursos/BLOG-4.png'},
                {title: 'ENTRADA 5', img: 'img/recursos/BLOG-5.png'},
                {title: 'ENTRADA 6', img: 'img/recursos/BLOG-6.png'} ]}}),_c('news-letters'),_c('SiteFooter')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }