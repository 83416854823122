<template>
  <div id="alcance">
    <section class="client-section pt-100" v-bind:class="isGray ? 'gray-light-bg' : ''" style="background-color: #F5F7F8;" >
      <div class="section-heading mb-5">
        <h2 style="text-align: center;">ALCANCE </h2>
      </div>
      <div class="container">
        <div class="wrapper ml-5 mr-5 pb-100">
          <div class="h5" style="padding-bottom: 30px;">
            <h5 style="color:#0D1E2B; text-align: center;">ROBÓTICA</h5>
            <card-estrategias
            img="img/pagina_inicio/Robotica.jpg"
            route="/robotica"
            style="width: 370px;"
          />
          </div>
          <div class="h5">
            <h5 style="color:#0D1E2B; text-align: center;">EMPAQUE</h5>
            <card-estrategias
            img="img/pagina_inicio/Empaque.jpg"
            route="/empaque"
            style="width: 370px;"
          />
          </div>
          <div class="h5">
            <h5 style="color:#0D1E2B; text-align: center;">EMBALAJE</h5>
            <card-estrategias
            img="img/pagina_inicio/Embalaje.jpg"
            route="/embalaje"
            style="width: 370px;"
          />
          </div>
          <div class="h5">
            <h5 style="color:#0D1E2B; text-align: center;">ETIQUETADO</h5>
            <card-estrategias
            img="img/pagina_inicio/Etiquetado.jpg"
            route="/etiquetado"
            style="width: 370px;"
          />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CardEstrategias from '../home/CardEstrategias.vue';

export default {
    name: "Estrategias",
    components: {
        CardEstrategias,
    }
};
</script>
<style scoped>
    @media (max-width: 1010px){
        .wrapper {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            justify-items: center;
            justify-content: center;
        }
    }
    @media (min-width: 1011px) {
        .wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;
            justify-content: center;
        }
    }

</style>