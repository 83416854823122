<template>
    <section>
        <div class="container row cifras">
            <div class="content">
                <h2 class="title">Kupi en cifras:</h2>
            </div>
            <div class="content">
                <h2 class="valor">250</h2>
                <h4 class="title">Fondeadores</h4>
            </div>
            <div class="content">
                <h2 class="valor">+3.700</h2>
                <h4 class="title">Comercios</h4>
            </div>
            <div class="content">
                <h2 class="valor">+$250mil</h2>
                <h4 class="title">Millones en transacciones</h4>
            </div>
        </div>
    </section>
</template>

<script>

// import img1 from 'assets/img/pagina_inicio/CONFIANZA.png';
export default {
    name: "Cifras",
    
    props: {
        title: {type: String},
        descripcion: {type: String},
        route: {type: String},
        img: {type: Number},
    }
};
</script>

<style scoped>
     .cifras {
        justify-content: space-around;
        align-items: center;
        margin: 50px 0;
    }

    .content {
        text-align: center;
    }

    .valor {
        color: #FF7777;
    }

    .title {
        color: #282447;
    }
</style>