<template>
    <section
        id="features"
        class="imageblock-section switchable switchable-content"
    >
        <div class="imageblock-section-img col-lg-5 col-md-4">
            <div
                class="background-image-holder features-6-bg-image"
                style="opacity: 1"
            ></div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-7">
                    <!--feature new style start-->
                    <div class="row align-items-center ptb-100">
                        <div class="col-lg-12">
                            <div class="d-flex align-items-start mb-5">
                                <div class="pr-4">
                                    <div
                                        class="icon icon-shape icon-color-1 rounded-circle"
                                    >
                                        <span class="ti-face-smile"></span>
                                    </div>
                                </div>
                                <div class="icon-text">
                                    <h5>Responsive web design</h5>
                                    <p class="mb-0">
                                        Modular and interchangable componente
                                        between layouts and even demos.
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex align-items-start mb-5">
                                <div class="pr-4">
                                    <div
                                        class="icon icon-shape icon-color-2 rounded-circle"
                                    >
                                        <span class="ti-vector"></span>
                                    </div>
                                </div>
                                <div class="icon-text">
                                    <h5>Loaded with features</h5>
                                    <p class="mb-0">
                                        Modular and interchangable componente
                                        between layouts and even demos.
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex align-items-start">
                                <div class="pr-4">
                                    <div
                                        class="icon icon-shape icon-color-3 rounded-circle"
                                    >
                                        <span class="ti-headphone-alt"></span>
                                    </div>
                                </div>
                                <div class="icon-text">
                                    <h5>Friendly online support</h5>
                                    <p class="mb-0">
                                        Modular and interchangable componente
                                        between layouts and even demos.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--feature new style end-->
                </div>
            </div>
            
        </div>
    </section>
</template>

<script>
export default {
    name: "Features",
};
</script>

<style>
.features-6-bg-image {
    background: url("../../assets/img/about-img.jpg") no-repeat center center /
        cover !important;
}
</style>