<template>
    <footer class="footer-section" id="footerSection">
        <!--footer top start-->
        <div class="footer-top py-5 background-img-2 bg-footer-custom">
            <div class="container">
                <div>
                    <!-- CONTACTO -->
                    <div class="col-lg-2 mb-3 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <!-- <img src="img/logo/logo-white-1x.png"
                                 alt="footer logo"
                                 width="120"
                                 class="img-fluid mb-3"/> -->
                            <!-- CONTACTO -->
                            <ul class="list-unstyled support-list">
                                <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-world mr-2"></span>
                                    <a href="#">www.packingsupport.com</a>
                                </li>
                                <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-email mr-2"></span
                                    ><a href="mailto:customerservice@packingsuppot.com">customerservice@packingsupport.com</a>
                                </li>
                                <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-mobile mr-2"></span>
                                    <a href="https://api.whatsapp.com/send?phone=573044454507">+57 304 4454507</a>
                                </li>
                                <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-home mr-2"></span>
                                    <a href="#">Carrera 48B # 54D - 40 Cali, Colombia</a>
                                </li>
                                <!-- <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-heart mr-2"></span>
                                    <router-link to="/kupilover">Ser un Kupilover</router-link>
                                </li> -->
                            </ul>
                            <!-- REDES SOCIALES -->
                            <!-- <div class="social-list-wrap">
                                <ul class="social-list list-inline list-unstyled">
                                    <li class="list-inline-item">
                                        <a href="https://www.facebook.com/appkupi" target="_blank" title="Facebook" ><span class="ti-facebook"></span></a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="https://www.instagram.com/appkupi/?utm_medium=copy_link" target="_blank" title="Instagram" ><span class="ti-instagram"></span></a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="https://twitter.com/appkupi?s=21&t=ZYq8CcFV6TFfEQJkaiI83A" target="_blank" title="Twitter" ><span class="ti-twitter-alt"></span></a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQG4HIhWxaku_AAAAYI8N-eoMfBzZ9_OPGsZH_k0E9KJMfrgKNejTrAEMLApqrxb6nJrOCWMyDYPbBZ-efukQOZgniIbgy4eZhmN6iHOggyOupHUSQ4gRcRLkcvUcuz4CsPK1EE=&original_referer=https://kupi.com.co/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fkupigroup" target="_blank" title="Linkedin" ><span class="ti-linkedin"></span></a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="https://www.youtube.com/channel/UC_x05PLv4L2O5ihUxJZimfA" target="_blank" title="Youtube" ><span class="ti-youtube"></span></a>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <!-- ALIADOS COMERCIALES -->
                    <!-- <div class="col-lg-2 ml-auto mb-4 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <h5 class="mb-3 text-white">Aliados comerciales</h5>
                            <ul class="list-unstyled">
                                <li class="mb-2"><router-link to="/comercios">Beneficios</router-link></li>
                                <li class="mb-2"><router-link to="/comercios">¿Cómo vende Kupi?</router-link></li>
                                <li class="mb-2"><router-link to="/comercios">Preguntas frecuentes</router-link></li>
                            </ul>
                        </div>
                    </div> -->
                    <!-- FONDEADORAS -->
                    <!-- <div class="col-lg-2 ml-auto mb-4 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <h5 class="mb-3 text-white">Fondeadores</h5>
                            <ul class="list-unstyled">
                                <li class="mb-2"><router-link to="/fondeadores">Sector solidario</router-link></li>
                                <li class="mb-2"><router-link to="/fondeadores">Empresas</router-link></li>
                                <li class="mb-2"><router-link to="/fondeadores">Caja de Compensación</router-link></li>
                                <li class="mb-2"><router-link to="/fondeadores">Gobierno</router-link></li>
                            </ul>
                        </div>
                    </div> -->
                    <!-- USUARIOS -->
                    <!-- <div class="col-lg-2 ml-auto mb-4 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <h5 class="mb-3 text-white">Usuarios</h5>
                            <ul class="list-unstyled">
                                <li class="mb-2"><router-link to="/usuarios">¿Cómo utilizar Kupi?</router-link></li>
                                <li class="mb-2"><router-link target="_blanck" to="/convenios">¿Dónde comprar?</router-link></li>
                                <li class="mb-2"><router-link to="/usuarios">¿Tienes dudas?</router-link></li>
                            </ul>
                            <div class="social-list-wrap">
                                <ul class="social-list list-inline list-unstyled">
                                    <li class="mb-2">¡Descárgala ya!</li>
                                    <li class="list-inline-item">
                                        <a href="https://apps.apple.com/co/app/kupi/id1228370904?platform=iphone" target="_blank" title="App Store" ><span class="ti-apple"></span></a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="https://play.google.com/store/apps/details?id=com.ingeniapps.dicmax&hl=es_CO&gl=US" target="_blank" title="Google Pay" ><span class="ti-android"></span></a>
                                    </li>
                                    <li class="mb-2">
                                        <a href="https://s3.amazonaws.com/app.kupi.s3/files/app/kupi.apk" title="APK" style="font-size: x-small;"><span class="ti-android"></span> Descarga APK</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> -->
                    <!-- LEGAL -->
                    <!-- <div class="col-lg-2 ml-auto mb-4 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <h5 class="mb-3 text-white">Legal</h5>
                            <ul class="list-unstyled">
                                <li class="mb-2"><router-link to="/tratamiento-datos">Política de tratamiento de datos</router-link></li>
                                <li class="mb-2"><a href="https://s3.amazonaws.com/app.kupi.s3/tratamientoDatos/Politica_Tratamiento_de_Datos_KUPI_2018.pdf">Política de tratamiento de datos</a></li>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!--footer top end-->

        <!--footer copyright start-->
        <div class="footer-bottom gray-light-bg pt-4 pb-4">
            <div class="container">
                <div class="row text-center justify-content-center">
                    <div class="col-md-6 col-lg-5">
                        <p class="copyright-text pb-0 mb-0">
                            Packing Support - 2024
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!--footer copyright end-->
    </footer>
</template>

<script>
export default {
    name: "SiteFooter",
};
</script>

<style scoped lang="scss">
.bg-footer-custom {
    background-color: rgb(245, 247, 248);
    color: black;
}
.footer-nav-wrap ul li a, .support-list li span{
    color: #0D1E2B;
}
</style>