<template>
    <section
        class="pt-100"
        style="background: #282447;"
    >
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-6 col-lg-5">
                    <div class="position-relative mt-lg-0 mt-md-0 mt-5 text-white">
                        <h1 class="text-white mb-10">
                            Recursos
                        </h1>
                        <h4 class="text-white mb-10">"La tecnología financiera está transformando el panorama financiero y ha demostrado que puede mejorar el acceso y la eficiencia en los servicios financieros."</h4>
                        <p class="lead" style="line-height: 24px;">
                            Christine Lagarde, presidenta del Banco Central Europeo.
                        </p>
                        <a href="/contact" class="btn google-play-btn boton">ÚNETE A LA REVOLUCIÓN KUPI</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-7">
                    <div class="offer-tag-wrap position-relative z-index">
                        <img
                            src="img/recursos/leyendo-en-pc.png"
                            alt="app"
                            class="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "Banner",
};
</script>

<style scoped>
    .boton {
        color: #FFFFFF;
        background: #FF7777;
        border-color: #FF7777;
    }
</style>