<template>
    <section class="promo-section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>Kupi responde a tus preguntas</h2>
                    </div>
                </div>
            </div>
            <div class="row equal">
                <div class="col-lg-6">
                    <accordian id="accordion-1" :contents="contents" />
                </div>
                <div class="col-lg-6">
                    <accordian id="accordion-2" :contents="contents1" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Accordian from "../../components/Accordian";
export default {
    name: "Preguntas",
    components: {
        Accordian,
    },
    data: function () {
        return {
            contents: [
                {
                    title: "¿Qué es Kupi?",
                    description: "Es una plataforma tecnológica que permite a los Fondos de Empleados y Cooperativas realizar asignaciones para comprar en establecimientos de comercio de la red Comercial sin intereses ni costos de utilización.",
                    active: false,
                    icon: "ti-receipt",
                },
                {
                    title: "¿Qué proceso debo hacer como cajero?",
                    description: "Vender con Kupi es muy fácil:\n1. Ingresa a www.kupi.com.co con tu cédula y contraseña.\n2. Realiza el registro según la opción con que cuente el usuario QR (si tiene la aplicación) o PIN si tiene un celular básico.\n3. Digita el valor de la venta y la información que te sea útil (número de factura, o referencia del producto).",
                    active: false,
                    icon: "ti-gallery",
                },
                {
                    title: "¿Quién paga la compra del usuario Kupi?",
                    description: "Kupi paga al establecimiento la totalidad de las compras realizadas por los usuarios los días miércoles o jueves de cada semana, pues la empresa que ha otorgado el cupo paga así misma la totalidad de dichas compras.",
                    active: false,
                    icon: "ti-wallet",
                },
            ],
            contents1: [
                {
                    title: "¿Cómo puedo ser cajero Kupi?",
                    description: "Es compromiso del propietario del establecimiento, contar con el personal autorizado para brindar la atención que nuestros usuarios esperan. El propietario del establecimiento cuenta con la opción de vender, pero si otras personas tienen manejo de caja, debe registrarlas desde la opción de usuarios del sistema",
                    active: false,
                    icon: "ti-receipt",
                },
                {
                    title: "¿Qué beneficio tengo como cajero?",
                    description: "Al ser parte de nuestro mundo de oportunidades estás brindando una nueva alternativa de pago a usuarios que no cuentan con efectivo, no poseen sistemas de crédito tradicionales o están buscando mejorar el manejo de sus finanzas al no asumir costos de financiación. Por estar vinculado a nuestra plataforma cada vez que confirmas una venta estás siendo calificado de manera positiva por nuestros usuarios y estás participando por reconocimientos. Mi establecimiento es vinculado a Kupi pero yo no lo estoy como cajero Escríbenos a contacto@kupi.com.co nombre del establecimiento, tu nombre, cédula, celular y correo ¡Con gusto asociaremos tus datos al establecimiento que representas!",
                    active: false,
                    icon: "ti-lock",
                },
                {
                    title: "¿Como cajero debo hacer algún estudio de crédito o procesos de validación?",
                    description: "No debes realizar ningún proceso adicional para vender. Nuestros usuarios ya tienen sus cupos asignados y solo debes prestar tu mejor servicio.",
                    active: false,
                    icon: "ti-widget",
                },
            ],
        };
    },
};
</script>

<style>
</style>