<template>
  <section class="ptb-100">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-md-6">
            <div class="section-heading">
              <h2 class="color" style="text-align: center; padding-bottom: 20px;">{{ title }}</h2>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle1 }}</h5>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle2 }}</h5>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle3 }}</h5>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle4 }}</h5>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle5 }}</h5>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle6 }}</h5>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle7 }}</h5>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle8 }}</h5>
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-heading">
              <h2 class="color" style="text-align: center; padding-bottom: 20px;">{{ title2 }}</h2>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle21 }}</h5>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle22 }}</h5>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle23 }}</h5>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle24 }}</h5>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle25 }}</h5>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle26 }}</h5>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle27 }}</h5>
              <h5 class="color" style="padding-left: 30px;">{{ subtitle28 }}</h5>
            </div>
          </div>
        </div>
      </div>
  </section>
</template>
<script>
export default {
  name: "Sections",
  props: {
      imageLeft: { type: Boolean, default: false },
      title: {type: String},
      title2: {type: String},
      span: {type: String},
      subtitle1: {type: String},
      subtitle2: {type: String},
      subtitle3: {type: String},
      subtitle4: {type: String},
      subtitle5: {type: String},
      subtitle6: {type: String},
      subtitle7: {type: String},
      subtitle8: {type: String},
      subtitle9: {type: String},
      subtitle21: {type: String},
      subtitle22: {type: String},
      subtitle23: {type: String},
      subtitle24: {type: String},
      subtitle25: {type: String},
      subtitle26: {type: String},
      subtitle27: {type: String},
      subtitle28: {type: String},
      subtitle29: {type: String},
      img: {type: String},
  }
};
</script>

<style scoped>

  .color {
      color: #0D1E2B;
  }
</style>