<template>
    <section id="about" class="about-us ptb-100 gray-light-bg">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="about-content-right">
                        <img
                            src="img/delivery-app.svg"
                            width="500"
                            alt="about us"
                            class="img-fluid"
                        />
                    </div>
                </div>
                <div class="col-lg-5">
                    <span class="badge badge-primary badge-pill"
                        >About our app</span
                    >
                    <h2 class="mt-4">Deliver your product using AppCo</h2>
                    <p class="mb-4 lh-190">
                        Quick has all the right tools in order to make your
                        website building process a breeze and automatize your
                        time-consuming tasks in your development workflow.
                    </p>
                    <ul class="list-unstyled">
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div
                                        class="badge badge-circle badge-primary mr-3"
                                    >
                                        <span class="ti-check"></span>
                                    </div>
                                </div>
                                <div>
                                    <p class="mb-0">Tones of SASS variables</p>
                                </div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div
                                        class="badge badge-circle badge-primary mr-3"
                                    >
                                        <span class="ti-check"></span>
                                    </div>
                                </div>
                                <div>
                                    <p class="mb-0">
                                        Create your own skin to match your brand
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div
                                        class="badge badge-circle badge-primary mr-3"
                                    >
                                        <span class="ti-check"></span>
                                    </div>
                                </div>
                                <div>
                                    <p class="mb-0">
                                        Globally orchestrate tactical channels
                                        whereas bricks
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div
                                        class="badge badge-circle badge-primary mr-3"
                                    >
                                        <span class="ti-check"></span>
                                    </div>
                                </div>
                                <div>
                                    <p class="mb-0">
                                        Use Gulp to prepare all assets for
                                        production
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div
                                        class="badge badge-circle badge-primary mr-3"
                                    >
                                        <span class="ti-check"></span>
                                    </div>
                                </div>
                                <div>
                                    <p class="mb-0">
                                        Collaboratively predominate vertical
                                        manufactured
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "About",
};
</script>

<style>
</style>