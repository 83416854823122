<template>
  <section>
    <section class="hero-section hero-section-2 ptb-100">
      <div class="container">
        <div class="justify-content-center">
          <div class="section-heading text-center mt-5 mb-5">
            <h2 class="text-white">Busca comercios aliados Kupi en tu ciudad</h2>
          </div>
        </div>
        <div class="row align-items-center justify-content-between">
            <div class="col-md-5 col-lg-6">
              <div>
                <label class="lead text-white">
                  Seleccionar Ciudad
                </label>
                <multiselect
                  :options="cities"
                  v-model="city"
                  :close-on-select="true"
                  label="name"
                  :allow-empty="false"
                  placeholder="Seleccione una ciudad"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="Seleccionado"
                  @search-change="typed = $event"
                  @input="onChangeCity"
                >
                </multiselect>
              </div>
              <div v-if="this.city.name" class="mt-5">
                <label class="lead text-white">
                    Seleccione una categoría
                  </label>
                  <multiselect
                    :options="categories"
                    v-model="category"
                    :close-on-select="true"
                    label="name"
                    :allow-empty="false"
                    placeholder="Seleccione una categoría"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel="Seleccionada"
                    @search-change="typed = $event"
                    @input="onChangeCategory"
                  >
                  </multiselect>
              </div>
            </div>
            <div class="col-md-5 col-lg-6">
              <div style="text-align: center;">
                <div class="row align-items-center">
                    <b-carousel
                      v-model="slide"
                      :interval="3000"
                      controls
                      indicators
                      background="#ababab"
                      img-width="1024"
                      img-height="480"
                      style="text-shadow: 1px 1px 2px #333;"
                      @sliding-start="onSlideStart"
                      @sliding-end="onSlideEnd"
                    >
                      <b-carousel-slide
                        v-for="promo in promos" :key="promo.codPromo"
                        :img-src="promo.img"
                      >
                      </b-carousel-slide>
                    </b-carousel>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
    <section class="g-bg-gray-light-v5">
      <div class="container">
        <div class="shortcode-html">
          <div class="row equal item-empresa">
              <div v-for="company in companies" :key="company.codEmpresa" class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                  <Empresa
                      :empresa="company"
                      :empresa2="company"
                      :is-rounded="true"
                  />
              </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import Empresa from "./Empresa";
import Multiselect from 'vue-multiselect';
import { BCarousel, BCarouselSlide } from 'bootstrap-vue';
import axios from 'axios';
import Vue from 'vue';
Vue.component('b-carousel', BCarousel)
Vue.component('b-carousel-slide', BCarouselSlide)

export default {
  name: 'ConveniosKupi',
  components: {
    Multiselect,
    Empresa,
  },
  mounted () {
    this.getCiudades()
    this.getPromos()
  },
  data () {
    return {
      url3: 'https://kupi.com.co/ws3/public',
      slide: 0,
      sliding: null,
      city: '',
      typed: '',
      promos: [],
      promo: [],
      cities: [],
      categories: [
        {
          id: 0,
          name: 'Seleccione una categoría'
        }
      ],
      companies: [],
      company: [],
      company2: [],
      showCity: false,
      category: '',
      select: {
        visible: false
      },
      api: 'https://api.whatsapp.com/send?phone='
    }
  },
  methods: {
    onSlideStart () {
      this.sliding = true
    },

    onSlideEnd () {
      this.sliding = false
    },

    getPromos () {
      axios.get(`https://kupi.com.co/ws/getPromos`).then(res => {
          for (var index = 0; index < res.data.promos.length; index++) {
            this.promos.push({codPromo: res.data.promos[index].codPromo, img: res.data.promos[index].imgPromo})
          }
        })
        .catch(err => {
          console.error(err)
        })
    },

    getCiudades () {
      axios
        .get(`${this.url3}/cities/categories`)
        .then(res => {
          for (var i = 0; i < res.data.cities.length; i++) {
            var objeto =
            {
              'id': res.data.cities[i].codCiudad,
              'name': res.data.cities[i].nomCiudad
            }
            this.cities.push(objeto)
          }
        })
        .catch(err => {
          console.error(err)
        })
    },

    getCategories (city) {

      var data = {
        codVersion: "7.0.0",
        buscar : "",
        codApp : "100",
        categoria: this.category,
        codCiudad: this.city.id
      }

      axios.post(`https://kupi.com.co/ws3/public/getEmpresas`, data)
      .then(res => {
        console.log("WS de campanas:", res.data)
        //ACA PROCESE LA INFOORMACION NO JODAAA EQUISDE
      })
      .catch(err => {
        console.error(err)
      })

      
      axios
        .get(`${this.url3}/categories/city/${city}`)
        .then(res => {
          this.categories = []
          var todas =
          {
            'id': '',
            'name': 'Todas'
          }
          this.categories.push(todas)
          for (var i = 0; i < res.data.categories.length; i++) {
            var objeto =
            {
              'id': res.data.categories[i].idCategoria,
              'name': res.data.categories[i].nomCategoria
            }
            this.categories.push(objeto)
          }
        })
        .catch(err => {
          console.error(err)
        })
      axios
        .get(`${this.url3}/companies/city/${city}`)
        .then(res => {
          this.companies = []
          this.companies = res.data.companies
        })
        .catch(err => {
          console.error(err)
        })
    },

    onChangeCity: function () {
      this.getCategories(this.city.id)
    },

    onChangeCategory: function () {
    
      var category = this.category.id
      var city = this.city.id
      if (category) {
        axios
          .get(`${this.url3}/companies/citycat/${city}/${category}`)
          .then(res => {
            this.companies = []
            this.companies = res.data.companies
          })
          .catch(err => {
            console.error(err)
          })
      } else {
        axios
          .get(`${this.url3}/companies/city/${city}`)
          .then(res => {
            this.companies = []
            this.companies = res.data.companies
          })
          .catch(err => {
            console.error(err)
          })
      }
    },

    getComercioLinea () {
      axios
        .get(`${this.url3}/companiesOnline`)
        .then(res => {
          this.companies = res.data.companies
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
    .carousel-item img {
        height:45vh!important ;
    }
</style>