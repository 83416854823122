import { render, staticRenderFns } from "./ReviewBlog.vue?vue&type=template&id=598b0602&"
import script from "./ReviewBlog.vue?vue&type=script&lang=js&"
export * from "./ReviewBlog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports