<template>
    <div v-if="radiusLeft" class="col-md-6 l-img">
        <div class="about-content-right">
        <img
            :src="src"
            alt="about us"
            class="img-fluid"
            width="500"
        />
        </div>
    </div>

    <div v-else class="col-md-6 r-img">
        <div class="about-content-right">
        <img
            :src="src"
            alt="about us"
            class="img-fluid"
            width="500"
        />
        </div>
    </div>
</template>

<script>
export default {
    name: 'imgBeneficio',
    props: {
        radiusLeft: {
            type: Boolean,
            default: true
        },
        src: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
  .l-img img{
    border-radius: 10px 10px 200px 10px
  }

  .r-img img{
    border-radius: 10px 10px 10px 200px
  }
</style>