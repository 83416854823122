<template>
    <div
        id="features"
        class="feature-section ptb-100"
        v-bind:class="isGray ? 'gray-light-bg' : ''"
    >
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="section-heading text-center mb-5">
                        <span
                            v-if="showBadge"
                            class="badge badge-primary badge-pill"
                            >Best features</span
                        >
                        <h2>
                            Quick &amp; Easy Process with <br />
                            best features
                        </h2>
                        <p>
                            Objectively deliver professional value with diverse
                            web-readiness. Collaboratively transition wireless
                            customer service without goal-oriented catalysts for
                            change. Collaboratively.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row row-grid align-items-center">
                <div class="col-lg-4">
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div
                                class="icon icon-shape icon-color-1 rounded-circle"
                            >
                                <span class="ti-face-smile"></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Responsive web design</h5>
                            <p class="mb-0">
                                Modular and interchangable componente between
                                layouts and even demos.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div
                                class="icon icon-shape icon-color-2 rounded-circle"
                            >
                                <span class="ti-vector"></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Loaded with features</h5>
                            <p class="mb-0">
                                Modular and interchangable componente between
                                layouts and even demos.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start">
                        <div class="pr-4">
                            <div
                                class="icon icon-shape icon-color-3 rounded-circle"
                            >
                                <span class="ti-headphone-alt"></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Friendly online support</h5>
                            <p class="mb-0">
                                Modular and interchangable componente between
                                layouts and even demos.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="position-relative" style="z-index: 10">
                        <img
                            alt="Image placeholder"
                            src="img/image-10.png"
                            class="img-center img-fluid"
                        />
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div
                                class="icon icon-shape icon-color-4 rounded-circle"
                            >
                                <span class="ti-layout-media-right"></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Free updates forever</h5>
                            <p class="mb-0">
                                Modular and interchangable componente between
                                layouts and even demos.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div
                                class="icon icon-shape icon-color-5 rounded-circle"
                            >
                                <span class="ti-layout-cta-right"></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Built with Sass</h5>
                            <p class="mb-0">
                                Modular and interchangable componente between
                                layouts and even demos.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start">
                        <div class="pr-4">
                            <div
                                class="icon icon-shape icon-color-6 rounded-circle"
                            >
                                <span class="ti-palette"></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Infinite colors</h5>
                            <p class="mb-0">
                                Modular and interchangable componente between
                                layouts and even demos.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "Features",
    props: {
        showBadge: {
            type: Boolean,
            default: true,
        },
        isGray: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style>
</style>