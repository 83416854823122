<template>
    <section class="client-section pt-100 pb-100" v-bind:class="isGray ? 'gray-light-bg' : ''" id="technicalService" style="background-color: #0D1E2B;">
        <div class="section-heading text-center mb-5">
            <h2 style="color: #FFFFFF; letter-spacing: -1px;">SERVICIOS TÉCNICOS</h2>
        </div>
        <div class="container">
            <div class="row grid  offset-1 mt-5">
                <div class="mr-5 mt-5">
                    <div class="card single-promo-card single-promo-hover">
                        <div class="card-body">
                            <div class="pb-2">
                                <span
                                    class="ti-light-bulb icon-md color-secondary"
                                ></span>
                            </div>
                            <div class="pt-2 pb-3">
                                <h5>Resolución de problemas</h5>
                                <p class="text-muted mb-0">
                                    Identificamos realmente la causa raíz de manera eficiente y eficaz eliminando las averías para siempre.  Asimismo, mejoramos la productividad de su proceso.
                                    <br>
                                    <br>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mr-5 mt-5">
                    <div
                        class="card single-promo-card single-promo-hover mb-lg-0"
                    >
                        <div class="card-body">
                            <div class="pb-2">
                                <span
                                    class="ti-hand-stop icon-md color-secondary"
                                ></span>
                            </div>
                            <div class="pt-2 pb-3">
                                <h5>Instalaciones</h5>
                                <p class="text-muted mb-0">
                                    Garantizamos una configuración y un rendimiento óptimo desde el inicio del proceso productivo en la instalación de máquinas y equipos.
                                    <br>
                                    <br>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mr-5 mt-5">
                    <div
                        class="card single-promo-card single-promo-hover mb-lg-0"
                    >
                        <div class="card-body">
                            <div class="pb-2">
                                <span
                                    class="ti-check-box icon-md color-secondary"
                                ></span>
                            </div>
                            <div class="pt-2 pb-3">
                                <h5>Evaluaciones de líneas y equipos</h5>
                                <p class="text-muted mb-0">
                                    Identificamos los problemas y evaluamos oportunidades para mejorar la calidad y la productividad de su proceso eliminando los sobrecostos de producción y los desperdicios innecesarios.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mr-5 mt-5">
                    <div
                        class="card single-promo-card single-promo-hover mb-lg-0"
                    >
                        <div class="card-body">
                            <div class="pb-2">
                                <span
                                    class="ti-link icon-md color-secondary"
                                ></span>
                            </div>
                            <div class="pt-2 pb-3">
                                <h5>Capacitaciones</h5>
                                <p class="text-muted mb-0">
                                    Entrenamiento personalizado para solventar las necesidades de su operación. Brindamos a su personal la destreza para eliminar los desperdicios y averías, aumentando así la eficiencia de su proceso productivo.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mr-5 mt-5">
                    <div
                        class="card single-promo-card single-promo-hover mb-lg-0"
                    >
                        <div class="card-body">
                            <div class="pb-2">
                                <span
                                    class="ti-timer icon-md color-secondary"
                                ></span>
                            </div>
                            <div class="pt-2 pb-3">
                                <h5>Desarrollo de proyectos</h5>
                                <p class="text-muted mb-0">
                                    Implemente con éxito proyectos de calidad de empaque, productividad y ahorro de costos para máquinas, líneas e instalaciones de empaque únicas o múltiples.
                                    <br>
                                    <br>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mr-5 mt-5">
                    <div
                        class="card single-promo-card single-promo-hover mb-lg-0"
                    >
                        <div class="card-body">
                            <div class="pb-2">
                                <span
                                    class="ti-settings icon-md color-secondary"
                                ></span>
                            </div>
                            <div class="pt-2 pb-3">
                                <h5>Planes de mantenimiento</h5>
                                <p class="text-muted mb-0">
                                    Elimine sus paros no programados y gastos innecesarios ejecutando planes de mantenimiento que realmente le generen confiabilidad y disponibilidad en su proceso productivo.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "Revolucion",
};
</script>
<style scoped>
    @media (max-width: 767px){
        .grid {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
        .container {
        padding-left: 0;
        padding-right: 0;
    }
    }
    @media (min-width: 768px) and (max-width: 999px){
        .grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @media (min-width: 1000px) {
        .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
    }
    
    

</style>