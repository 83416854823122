<template>
  <div>
    <nav-bar />
    <div class="main">
      <Banner
          title="ROBÓTICA"
          text1="Especialistas en mantenimiento y reparación de robots industriales."       
          img1="img/recursos/robotica3.JPG"        
          img2="img/recursos/robotica4.JPG"                       
          img3="img/recursos/robotica1.JPG"        
      />
      <sectionRobotica
        title="Nuestros servicios incluyen:"
        subtitle1="•	Evaluación, instalación, configuración y puesta en marcha de robots."
        subtitle2="•	Resolución de problemas técnicos."
        subtitle3="•	Actualización de software y firmware."
        subtitle4="•	Calibración de sensores, cámaras y herramientas."
        subtitle5="•	Planes de mantenimiento."
        title2="Aplicaciones en la industria:"
        subtitle21="•	Manufacturera."
        subtitle22="•	Automotriz."
        subtitle23="•	Alimenticia."
        subtitle24="•	Farmacéutica"
        subtitle25="•	Logística"
        subtitle26="•	Construcción"
      />
      <div class="video-fondo">
        <video autoplay loop muted class="video-fondo-element">
          <source src="videos/robotica.mp4" type="video/mp4">
        </video>
      </div>
    </div>
    <SiteFooter/>
  </div>
</template>

<script>

import NavBar from "../../commons/NavBar.vue";
import Banner from "../../Industrias/components/Banner.vue"
import SectionRobotica from "../../Industrias/components/SectionRobotica.vue";
import SiteFooter from '../../../views/commons/SiteFooter';



export default {
    name: 'Robotica',
    components: {
    NavBar,
    Banner,
    SectionRobotica,
    SiteFooter
},
}
</script>

<style scoped>
  .video-fondo {
    position: relative;
    width: 50%;
    height: 50vh;
    overflow: hidden;
    left: 25%;
    margin-bottom: 80px;
  }

</style>